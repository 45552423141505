import React from 'react'

export default function() {
	return (
		<svg width='77' height='77' viewBox='0 0 77 77' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clip-path='url(#clip0)'>
				<path
					d='M76.3499 64.8186C75.308 68.3627 73.1199 71.4343 70.4369 73.9284C69.3168 74.9785 68.1446 75.9498 66.9203 76.8687H48.608C48.9988 76.7112 49.3634 76.5536 49.7542 76.3699C50.3533 76.1073 50.9524 75.8448 51.5255 75.556C54.7555 74.0071 57.9335 72.2482 60.877 70.1742C61.6324 69.6229 62.3878 69.0715 63.1172 68.4677C64.3415 67.3914 65.5137 66.21 66.5035 64.8973C66.9203 64.2673 67.285 63.5847 67.5976 62.9021C67.7278 62.4558 67.832 62.0095 67.9101 61.5369C67.9101 61.1694 67.9101 60.7756 67.858 60.4081C67.7017 59.8043 67.5194 59.2267 67.285 58.6491C66.6337 57.3627 65.8002 56.1551 64.8885 55.0262C62.2836 51.9546 59.1838 49.3031 56.0059 46.8353C55.1984 46.2052 54.3909 45.6014 53.5573 45.0238C50.4575 42.8186 47.2535 40.7446 43.9974 38.8019C43.9714 38.8019 43.9714 38.7756 43.9453 38.7756L50.2752 32.1074C53.5312 34.1288 56.6831 36.2553 59.7829 38.5131C62.7785 40.6921 65.6699 43.0023 68.3009 45.6014C69.7596 47.019 71.0881 48.5417 72.3645 50.1169C73.3022 51.272 74.1618 52.5322 74.8391 53.8711C75.308 54.7899 75.7508 55.735 76.0894 56.7064C76.4281 57.6778 76.6104 58.7016 76.7407 59.7255C76.8448 60.3818 76.8448 61.0644 76.7927 61.7207C76.7407 62.7971 76.6625 63.821 76.3499 64.8186Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M42.0684 27.6969C42.0684 27.6969 58.7395 30.5322 72.9621 46.5728L62.6208 56.7065L50.5081 49.8019L42.0684 27.6969Z'
					fill='#FF3333'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M70.7999 44.2626C71.5293 44.9977 72.2586 45.7852 72.962 46.5991L62.6206 56.7065L59.5469 54.9475L70.7999 44.2626Z'
					fill='#F47070'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M0.625486 64.8186C1.66743 68.3627 3.85553 71.4343 6.53854 73.9284C7.65864 74.9785 8.83083 75.9498 10.0551 76.8687H28.3674C27.9766 76.7112 27.612 76.5536 27.2212 76.3699C26.6221 76.1073 26.023 75.8448 25.4499 75.556C22.2199 74.0071 19.0419 72.2482 16.0984 70.1742C15.343 69.6229 14.5876 69.0715 13.8582 68.4677C12.6339 67.3914 11.4618 66.21 10.4719 64.8973C10.0551 64.2673 9.69044 63.5847 9.37785 62.9021C9.24761 62.4558 9.14341 62.0095 9.06527 61.5369C9.06527 61.1694 9.06527 60.7756 9.11737 60.4081C9.27366 59.8043 9.456 59.2267 9.69044 58.6491C10.3417 57.3627 11.1752 56.1551 12.0869 55.0262C14.6918 51.9546 17.7916 49.3031 20.9695 46.8353C21.777 46.2052 22.5846 45.6014 23.4181 45.0238C26.5179 42.8186 29.7219 40.7446 32.978 38.8019C33.004 38.8019 33.004 38.7756 33.0301 38.7756L26.7002 32.1074C23.4442 34.1551 20.2662 36.2816 17.1925 38.5393C14.1969 40.7183 11.3055 43.0286 8.67454 45.6276C7.21581 47.0453 5.88733 48.568 4.61094 50.1432C3.67319 51.2983 2.81358 52.5584 2.13631 53.8973C1.66743 54.8162 1.22461 55.7613 0.885973 56.7327C0.54734 57.704 0.364999 58.7279 0.234755 59.7517C0.13056 60.4081 0.13056 61.0906 0.182658 61.747C0.234755 62.7971 0.33895 63.821 0.625486 64.8186Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M34.9055 27.6969C34.9055 27.6969 18.2343 30.5322 4.01172 46.5728L14.3791 56.7065L26.4918 49.8019L34.9055 27.6969Z'
					fill='#FF3333'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M6.19915 44.2626C5.46979 44.9977 4.74042 45.7852 4.03711 46.5991L14.3785 56.7065L17.4522 54.9475L6.19915 44.2626Z'
					fill='#F47070'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M58.5313 76.8687C58.5574 75.8974 58.5834 74.8997 58.5834 73.9021C58.5834 48.0954 49.3361 27.1718 37.9528 27.1718C26.5696 27.1718 17.3223 48.0954 17.3223 73.9021C17.3223 74.8997 17.3483 75.8711 17.3744 76.8687H58.5313Z'
					fill='#FF3333'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M21.6211 48.6993V54.6587C21.6211 56.3652 23.0017 57.7828 24.7209 57.7828H26.9611C28.6542 57.7828 30.0609 56.3914 30.0609 54.6587V48.6993H21.6211Z'
					fill='#FF3333'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M30.0348 48.6993H21.6211V50.9045H30.0348V48.6993Z'
					fill='#F47070'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M27.5601 39.8783C27.8987 36.938 28.7583 33.9976 30.3473 31.4773C31.5976 29.4559 33.395 27.6444 35.6873 26.8831C37.7712 26.2005 39.9593 26.5418 41.8087 27.6707C43.9187 28.9571 45.4295 31.0573 46.4454 33.2888C47.9041 36.3867 48.4772 39.8783 48.373 43.2912C48.373 43.7112 49.0242 43.7112 49.0242 43.2912C49.1024 40.0621 48.6074 36.7805 47.3832 33.7876C46.3933 31.3723 44.8825 29.0621 42.7465 27.5131C40.871 26.148 38.5266 25.5442 36.2343 26.043C33.7857 26.568 31.806 28.2745 30.3473 30.2697C28.3155 33.0525 27.2735 36.4654 26.8828 39.8783C26.8828 40.2984 27.534 40.2984 27.5601 39.8783Z'
					fill='#262433'
				/>
				<path
					d='M49.0962 47.4931C50.2615 47.0327 50.8358 45.7074 50.3789 44.533C49.9221 43.3586 48.6071 42.7798 47.4419 43.2402C46.2766 43.7007 45.7024 45.0259 46.1592 46.2003C46.616 47.3747 47.931 47.9535 49.0962 47.4931Z'
					fill='#C0C0C0'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M49.4935 45.3652C49.4935 46.0478 48.9465 46.5991 48.2692 46.5991C47.5919 46.5991 47.0449 46.0478 47.0449 45.3652C47.0449 44.6826 47.5919 44.1313 48.2692 44.1313C48.9465 44.1313 49.4935 44.6826 49.4935 45.3652Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M24.6945 47.938C24.408 46.4941 24.4079 44.9451 24.7726 43.5012C25.0592 42.4248 25.5541 41.296 26.4398 40.6134C27.1691 40.0621 28.1069 40.0096 28.8883 40.4821C29.8521 41.0334 30.4513 42.1098 30.842 43.1074C31.4411 44.7089 31.5974 46.4941 31.3109 48.1742C31.2327 48.5943 31.8579 48.7781 31.936 48.358C32.2226 46.6778 32.1184 44.9451 31.5974 43.3174C31.2067 42.0836 30.5294 40.8497 29.4614 40.0621C28.5757 39.432 27.4296 39.3007 26.4658 39.8258C25.3978 40.4034 24.7466 41.5322 24.3559 42.6349C23.7307 44.3676 23.7046 46.3103 24.0693 48.0955C24.1475 48.5418 24.7726 48.358 24.6945 47.938Z'
					fill='#262433'
				/>
				<path
					d='M23.8086 54.0811C23.8086 54.0811 25.5278 56.1026 28.1848 54.4224C30.8417 52.7422 31.5711 49.5131 31.7795 47.6492'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M24.1468 53.7924C24.1468 54.1074 23.9123 54.3437 23.5998 54.3437C23.2872 54.3437 23.0527 54.1074 23.0527 53.7924C23.0527 53.4773 23.2872 53.2411 23.5998 53.2411C23.9123 53.2148 24.1468 53.4773 24.1468 53.7924Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M32.3772 54.0549C32.3772 54.0549 30.684 56.1026 28.001 54.4487C25.318 52.7947 24.5626 49.5919 24.3281 47.7279'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M32.0389 53.7661C32.0389 54.0811 32.2994 54.3174 32.5859 54.3174C32.8985 54.3174 33.133 54.0549 33.133 53.7661C33.133 53.451 32.8725 53.2148 32.5859 53.2148C32.2733 53.1885 32.0129 53.451 32.0389 53.7661Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M38.0316 35.389L43.033 28.6157C42.1994 28.1432 41.3398 27.7756 40.4802 27.5393L40.5062 23.8639C40.5062 22.6038 39.4903 21.5537 38.24 21.5274H37.2762C36.0259 21.5274 34.9839 22.5513 34.9579 23.8114L34.9318 27.6969C33.8899 28.0381 32.9 28.5632 31.9102 29.2458L38.0316 35.389Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M31.7266 29.3771L38.2648 36.7804L43.9174 29.2196C43.162 28.6945 42.3805 28.2744 41.573 27.9594L38.2387 33.21L33.8626 28.1169C33.1332 28.4582 32.4299 28.8783 31.7266 29.3771Z'
					fill='#F47070'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M45.4808 16.8019C45.4808 16.8019 47.6168 13.9666 46.653 10.1074C45.7153 6.24823 38.604 4.253 37.7444 4.48928C36.8848 4.72555 33.6547 4.88307 33.6547 4.88307C33.6547 4.88307 29.7735 4.85682 29.2525 8.71601C28.7576 12.5752 30.4507 17.0907 30.581 17.6945C30.7112 18.3509 43.0583 21.0812 45.4808 16.8019Z'
					fill='#262433'
				/>
				<path
					d='M48.0075 17.8783C48.0336 19.0072 47.1479 19.9785 46.0018 20.0048C44.8556 20.031 43.9179 19.1384 43.8658 18.0096C43.8397 16.8807 44.7254 15.9093 45.8715 15.8831C47.0177 15.8568 47.9554 16.7494 48.0075 17.8783Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M44.9864 18.2459C44.9864 18.1934 44.9864 18.1671 44.9864 18.1146C44.9864 18.0621 44.9864 18.0096 44.9864 17.9834C44.9864 17.9571 44.9864 17.9309 44.9864 17.9834C44.9864 17.9571 44.9864 17.9571 44.9864 17.9309C44.9864 17.9046 44.9864 17.8784 45.0125 17.8258C45.0385 17.7733 45.0385 17.6946 45.0646 17.6421C45.0906 17.5896 45.0385 17.6683 45.0646 17.6158C45.0646 17.5896 45.0906 17.5896 45.0906 17.5633C45.1167 17.5371 45.1167 17.4846 45.1427 17.4583C45.1427 17.4321 45.1688 17.4321 45.1688 17.4058C45.1948 17.3795 45.1427 17.4321 45.1688 17.3795C45.1948 17.3533 45.2209 17.327 45.2469 17.3008C45.2729 17.3008 45.2729 17.2745 45.299 17.2745C45.325 17.2483 45.2729 17.3008 45.299 17.2745C45.299 17.2745 45.325 17.2745 45.325 17.2483C45.3511 17.222 45.4032 17.1958 45.4292 17.1695C45.4292 17.1695 45.4553 17.1695 45.4553 17.1433H45.4813C45.4553 17.1433 45.4553 17.1433 45.4813 17.1433C45.5074 17.1433 45.5334 17.117 45.5595 17.117C45.6116 17.0908 45.6637 17.0908 45.7158 17.0908C45.7158 17.0908 45.7679 17.0908 45.7418 17.0908C45.7679 17.0908 45.7679 17.0908 45.7939 17.0908C45.82 17.0908 45.846 17.0908 45.8981 17.0908C45.9763 17.0908 46.0544 17.0908 46.1326 17.0908C46.2107 17.0908 46.2888 17.012 46.2888 16.9332C46.2888 16.8282 46.2107 16.7757 46.1326 16.7757C45.6897 16.7495 45.2209 16.8545 44.9343 17.222C44.752 17.4583 44.6738 17.7471 44.6738 18.0359C44.6738 18.0884 44.6738 18.1671 44.6738 18.2196C44.6738 18.2984 44.752 18.3772 44.8301 18.3772C44.9083 18.4034 45.0125 18.3509 44.9864 18.2459Z'
					fill='#0C0C0C'
				/>
				<path
					d='M27.7681 18.1671C27.7942 19.296 28.758 20.1886 29.9041 20.1623C31.0503 20.1361 31.9359 19.1647 31.9099 18.0358C31.8838 16.907 30.92 16.0144 29.7739 16.0406C28.6277 16.0931 27.7421 17.0382 27.7681 18.1671Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M31.129 18.3508C31.155 17.8258 30.8685 17.2744 30.3736 17.0644C30.1131 16.9594 29.8265 16.9594 29.566 16.9857C29.4879 16.9857 29.4358 17.1169 29.4619 17.1957C29.4879 17.3007 29.5661 17.3269 29.6702 17.3007C29.8786 17.2744 30.061 17.2744 30.2694 17.3532C30.2694 17.3532 30.3475 17.3794 30.2954 17.3532C30.3215 17.3532 30.3215 17.3794 30.3475 17.3794C30.3736 17.4057 30.4257 17.432 30.4517 17.4582C30.4778 17.4845 30.4517 17.4582 30.4778 17.4845C30.5038 17.4845 30.5038 17.5107 30.5299 17.5107C30.5559 17.537 30.582 17.5632 30.608 17.5895C30.608 17.5895 30.608 17.6157 30.6341 17.6157C30.608 17.5895 30.6341 17.5895 30.6341 17.6157C30.6341 17.642 30.6601 17.642 30.6601 17.6682C30.6861 17.6945 30.7122 17.7207 30.7122 17.7732C30.7122 17.7995 30.7382 17.7995 30.7382 17.8258C30.7382 17.8258 30.7643 17.8783 30.7382 17.852C30.7382 17.8783 30.7643 17.9045 30.7643 17.957C30.7903 18.0095 30.7903 18.062 30.8164 18.1408C30.8164 18.167 30.8164 18.1933 30.8424 18.2195C30.8424 18.2458 30.8685 18.2721 30.8424 18.2195V18.2458C30.8424 18.2983 30.8424 18.3508 30.8424 18.4033C30.7903 18.5608 31.1029 18.5608 31.129 18.3508Z'
					fill='#0C0C0C'
				/>
				<path
					d='M31.3889 19.5322C31.415 22.1575 33.577 24.284 36.1558 24.2578L39.7245 24.2315C42.3294 24.2053 44.4393 22.0263 44.4133 19.4272L44.3351 11.5513C44.3091 8.92604 42.1471 6.79955 39.5682 6.8258L35.9996 6.85206C33.3947 6.87831 31.2847 9.05731 31.3108 11.6564L31.3889 19.5322Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M38.3171 15.2005C38.3171 16.0144 38.291 16.802 38.291 17.6158C38.291 17.7208 38.291 17.8521 38.291 17.9571C38.291 18.1671 38.6036 18.1671 38.6296 17.9571C38.6296 17.1432 38.6557 16.3557 38.6557 15.5418C38.6557 15.4368 38.6557 15.3055 38.6557 15.2005C38.6557 14.9642 38.3171 14.9642 38.3171 15.2005Z'
					fill='#262433'
				/>
				<path
					d='M33.055 14.1766C33.3415 14.0716 33.6541 13.9404 33.9407 13.8353C33.9928 13.8091 34.0188 13.8091 34.0709 13.7828C34.1491 13.7566 34.2011 13.6778 34.1751 13.5728C34.1491 13.4941 34.0709 13.4153 33.9667 13.4678C33.6802 13.5728 33.3676 13.7041 33.0811 13.8091C33.029 13.8353 33.0029 13.8353 32.9508 13.8616C32.8727 13.8879 32.8206 13.9666 32.8466 14.0716C32.8727 14.1504 32.9769 14.2291 33.055 14.1766Z'
					fill='#262433'
				/>
				<path
					d='M41.5196 14.2029C41.8322 14.2029 42.1447 14.1766 42.4573 14.1766C42.5094 14.1766 42.5355 14.1766 42.5876 14.1766C42.6657 14.1766 42.7439 14.0978 42.7439 14.0191C42.7439 13.9403 42.6657 13.8616 42.5876 13.8616C42.275 13.8616 41.9624 13.8878 41.6498 13.8878C41.5977 13.8878 41.5717 13.8878 41.5196 13.8878C41.4414 13.8878 41.3633 13.9666 41.3633 14.0453C41.3633 14.1241 41.4414 14.2029 41.5196 14.2029Z'
					fill='#262433'
				/>
				<path
					d='M41.3125 15.358C41.3385 15.7781 41.6772 16.0931 42.094 16.0931C42.5107 16.0669 42.8233 15.7256 42.8233 15.3055C42.7973 14.8855 42.4586 14.5704 42.0419 14.5704C41.6251 14.5967 41.3125 14.938 41.3125 15.358Z'
					fill='#0C0C0C'
				/>
				<path
					d='M33.3414 15.358C33.3674 15.7781 33.706 16.0931 34.1228 16.0931C34.5396 16.0669 34.8522 15.7256 34.8522 15.3055C34.8261 14.8855 34.4875 14.5704 34.0707 14.5704C33.6279 14.5967 33.3153 14.938 33.3414 15.358Z'
					fill='#0C0C0C'
				/>
				<path
					d='M44.3876 17.747C43.3196 18.6659 41.5223 19.3222 40.298 17.6158C39.256 16.1718 37.4066 16.0406 35.4008 17.747C33.6556 19.2434 31.728 17.9308 31.3633 17.6683L31.3893 19.5322C31.4154 22.1575 33.5774 24.284 36.1562 24.2578L39.7249 24.2315C42.3298 24.2053 44.4397 22.0263 44.4137 19.4272L44.3876 17.747Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M30.1906 8.16469C30.6595 7.53461 32.2745 6.45824 33.5249 5.88068C33.2644 -0.0262455 48.9717 -3.38663 45.6636 5.14559C45.6636 5.14559 50.8472 2.91409 51.7069 5.53939C53.8168 11.9189 38.7346 13.9141 34.4626 8.24344C33.577 8.58473 32.4569 9.24106 32.2485 10.4224C31.8577 12.4964 31.3368 17.0907 31.3368 17.0907C31.3368 17.0907 31.0763 15.9881 30.6595 15.043C30.2427 14.1504 29.5394 9.08354 30.1906 8.16469Z'
					fill='#262433'
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='77' height='77' fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}
