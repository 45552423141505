import React from 'react'

interface IProps {
	width: string
	height: string
}
export default function(props: IProps) {
	const { width = '134', height = '77' } = props
	return (
		<svg
			viewBox='0 0 134 77'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
			width={width}
			height={height}
		>
			<rect x='0.5' y='0.5' width='133' height='76' rx='8' fill='#CCCCCC' />
			<rect x='9.5' y='8.5' width='50' height='50' rx='4' fill='#999999' />
			<mask
				id='mask0'
				mask-type='alpha'
				maskUnits='userSpaceOnUse'
				x='9'
				y='8'
				width='51'
				height='51'
			>
				<rect x='9.5' y='8.5' width='50' height='50' rx='4' fill='#CCD3F5' />
			</mask>
			<g mask='url(#mask0)'>
				<path
					d='M32.6602 49.4039L62.7414 61.9301H81.2108V67.8732L56.9355 71.4848L35.1745 57.9071L32.6602 49.4039Z'
					fill='#FF4F5B'
				/>
				<g opacity='0.3'>
					<path
						opacity='0.3'
						d='M32.6602 49.4039L62.7414 61.9301H81.2108V67.8732L56.9355 71.4848L35.1745 57.9071L32.6602 49.4039Z'
						fill='black'
					/>
				</g>
				<path
					d='M42.3525 61.6558C42.3525 61.7015 42.8097 61.5187 43.404 61.1986C44.044 60.8329 44.8669 60.2843 45.6441 59.4614C46.4212 58.6842 46.9698 57.8156 47.2898 57.1756C47.6099 56.5356 47.747 56.1241 47.7013 56.0784C47.6099 56.0327 46.9698 57.7242 45.4612 59.2786C43.9526 60.8329 42.3068 61.5644 42.3525 61.6558Z'
					fill='#FAFAFA'
				/>
				<path
					d='M51.5393 17.3567C49.7107 15.9853 46.8762 17.0824 44.9104 15.9395C43.7218 15.2538 43.0818 13.8823 42.1675 12.8308C40.796 11.2765 38.4187 10.2707 36.3615 10.545C34.3043 10.8193 32.8414 11.3679 31.927 13.2423C30.647 11.7794 27.3097 12.8308 25.7096 14.4766C24.1096 16.0767 23.6524 18.1796 23.4695 20.4197C23.1038 24.3056 23.5152 28.2372 24.6582 31.9402C24.7496 32.2602 24.9782 32.6717 25.2982 32.626C25.5268 32.5803 25.7096 32.3517 25.8011 32.1688C26.7611 30.3859 27.2183 28.3744 27.7211 26.4086C28.224 24.4428 28.8183 22.4313 30.0527 20.8312C31.287 19.2311 33.2985 18.0425 35.31 18.3625C36.8187 18.5911 38.0987 19.5511 39.0588 20.6483C40.0645 21.7912 40.796 23.0713 41.7103 24.2599C42.6246 25.4485 43.7218 26.5914 45.0933 27.14C46.5105 27.6886 48.2477 27.5515 49.2992 26.5C50.3507 25.4485 50.305 23.3913 49.0249 22.5684C50.3507 22.8884 51.8136 22.2027 52.4079 20.9683C53.0022 19.734 52.6365 18.1339 51.5393 17.3567Z'
					fill='#1A2E35'
				/>
				<path
					d='M33.5726 48.3524L34.8069 44.1922C34.8069 44.1922 40.5671 45.0608 42.3501 39.5749C43.2187 36.9233 44.5445 31.346 45.6416 26.2714C46.876 20.8312 43.6759 15.3453 38.3271 13.6538L37.7785 13.4709C31.9725 12.008 27.4923 16.1682 26.2122 22.0198L19.9034 44.3751C18.8519 48.1238 21.0006 52.0554 24.7493 53.1526C28.4981 54.2498 32.4297 52.1011 33.5726 48.3524Z'
					fill='#FFBF9D'
				/>
				<path
					d='M34.807 44.1922C34.807 44.1922 31.2411 43.415 28.2695 39.9406C28.2695 39.9406 28.7267 44.4208 34.3955 45.6094L34.807 44.1922Z'
					fill='#FF9A6C'
				/>
				<path
					d='M42.1199 29.4958C42.0285 29.9072 41.5713 30.1815 41.1142 30.0444C40.657 29.9529 40.4284 29.4958 40.5199 29.0843C40.6113 28.6729 41.0685 28.3986 41.5256 28.5357C41.9828 28.6272 42.2114 29.0386 42.1199 29.4958Z'
					fill='#1A2E35'
				/>
				<path
					d='M43.6324 28.5573C43.4953 28.6487 43.0381 28.0087 42.2152 27.7344C41.3923 27.4601 40.6152 27.6887 40.5695 27.5515C40.5237 27.5058 40.7066 27.3229 41.0266 27.2315C41.3466 27.1401 41.8495 27.0486 42.3981 27.2315C42.901 27.4144 43.3124 27.7344 43.4953 28.0087C43.6782 28.3287 43.6782 28.5115 43.6324 28.5573Z'
					fill='#1A2E35'
				/>
				<path
					d='M34.1199 27.4957C34.0285 27.9072 33.5713 28.1815 33.1142 28.0443C32.657 27.9529 32.4284 27.4957 32.5199 27.0843C32.6113 26.6728 33.0685 26.3985 33.5256 26.5357C33.9828 26.6271 34.2571 27.0386 34.1199 27.4957Z'
					fill='#1A2E35'
				/>
				<path
					d='M35.2653 26.1343C35.1281 26.2257 34.6709 25.5857 33.848 25.3114C33.0252 25.0371 32.248 25.2657 32.2023 25.1285C32.1565 25.0828 32.3394 24.9 32.6594 24.8085C32.9794 24.7171 33.4823 24.6257 34.0309 24.8085C34.5338 24.9914 34.9452 25.3114 35.1281 25.5857C35.311 25.9057 35.311 26.0886 35.2653 26.1343Z'
					fill='#1A2E35'
				/>
				<path
					d='M36.4089 33.3574C36.4089 33.3117 36.9575 33.3574 37.8718 33.5403C38.1004 33.586 38.329 33.586 38.3747 33.4489C38.4661 33.3117 38.4661 33.0374 38.4204 32.7631C38.3747 32.1688 38.329 31.5745 38.2833 30.9345C38.1004 28.3287 38.0547 26.18 38.1918 26.18C38.2833 26.18 38.5118 28.2829 38.6947 30.8888C38.7404 31.5288 38.7404 32.1231 38.7861 32.7174C38.7861 32.9917 38.8319 33.3117 38.649 33.586C38.5576 33.7232 38.329 33.8146 38.1918 33.7689C38.0547 33.7689 37.9175 33.7232 37.8261 33.7232C36.9118 33.586 36.3632 33.4032 36.4089 33.3574Z'
					fill='#1A2E35'
				/>
				<path
					d='M33.7121 33.1746C33.8493 33.2203 33.575 34.1346 34.1693 35.0032C34.7636 35.9176 35.7693 36.1004 35.7236 36.2376C35.7236 36.2833 35.4493 36.329 35.0379 36.2376C34.6264 36.1461 34.0778 35.8261 33.7121 35.2775C33.3464 34.7289 33.3007 34.1346 33.3921 33.7689C33.4835 33.3575 33.6664 33.1289 33.7121 33.1746Z'
					fill='#1A2E35'
				/>
				<path
					d='M36.6823 21.837C36.5452 22.0198 35.7223 21.6541 34.6708 21.4713C33.6651 21.2427 32.7507 21.2884 32.705 21.0598C32.6593 20.9227 32.8879 20.7855 33.2536 20.6484C33.6651 20.5112 34.2136 20.4655 34.8537 20.6026C35.448 20.7398 35.9966 20.9684 36.3166 21.2427C36.6366 21.517 36.7738 21.7456 36.6823 21.837Z'
					fill='#1A2E35'
				/>
				<path
					d='M44.0875 25.9971C43.8589 26.1343 43.356 25.7686 42.6703 25.54C41.9845 25.3114 41.3445 25.2657 41.253 25.0371C41.2073 24.9 41.3902 24.7628 41.7102 24.6714C42.0302 24.5799 42.4874 24.5342 42.9903 24.7171C43.4474 24.9 43.8132 25.1743 43.996 25.4485C44.1332 25.7228 44.1789 25.9514 44.0875 25.9971Z'
					fill='#1A2E35'
				/>
				<path
					d='M25.4366 26.2714C25.3452 26.18 22.0079 23.9856 20.9564 27.7801C19.905 31.5745 23.928 31.8488 23.9737 31.7574C23.9737 31.6202 25.4366 26.2714 25.4366 26.2714Z'
					fill='#FFBF9D'
				/>
				<path
					d='M23.1959 29.9744C23.1959 29.9744 23.1044 30.0201 23.013 30.0201C22.8758 30.0201 22.693 30.0201 22.5101 29.883C22.1444 29.6087 22.0072 28.8772 22.1444 28.1915C22.2358 27.8257 22.373 27.5515 22.6015 27.2772C22.7844 27.0486 23.013 26.8657 23.2416 26.9114C23.4702 26.9114 23.5616 27.0943 23.5616 27.1857C23.5616 27.3229 23.5159 27.3686 23.5616 27.3686C23.5616 27.3686 23.653 27.3229 23.653 27.14C23.653 27.0486 23.653 26.9571 23.5616 26.8657C23.4702 26.7743 23.3787 26.6828 23.2416 26.6828C22.9216 26.6371 22.6015 26.82 22.373 27.0943C22.1444 27.3686 21.9615 27.6886 21.8701 28.1C21.6872 28.8772 21.8701 29.7001 22.373 30.0201C22.6015 30.1573 22.8758 30.1573 23.013 30.0658C23.1501 30.0658 23.1959 29.9744 23.1959 29.9744Z'
					fill='#FF9A6C'
				/>
				<path
					d='M47.149 25.4485C44.5432 25.3571 41.9831 23.8942 40.5659 21.6541C39.5602 20.0997 39.0573 18.1339 37.5486 17.1282C36.5429 16.4424 35.2171 16.3053 33.9828 16.5339C32.7941 16.7624 31.6512 17.4025 30.6455 18.1339C30.8284 21.2426 29.1826 24.1685 27.5368 26.82C27.3539 27.0943 27.1711 27.4143 26.851 27.5515C26.3024 27.7343 25.7081 27.2772 25.4338 26.7743C25.0224 25.9514 25.0681 24.9456 25.1595 23.9856C25.4338 21.8369 26.0281 19.734 27.0796 17.9053C28.1311 16.031 29.6854 14.4309 31.6055 13.4709C33.9828 12.2822 36.8629 12.1451 39.423 13.0137C41.9831 13.8366 44.1775 15.5738 45.7318 17.7682C46.4633 18.774 47.0576 19.9169 47.1948 21.1512'
					fill='#1A2E35'
				/>
				<path
					d='M52.6837 19.1397C52.6837 19.1397 52.6837 19.2768 52.5466 19.4597C52.4094 19.6426 52.1808 19.9169 51.7694 20.054C51.3579 20.2369 50.8093 20.2369 50.215 20.0997C49.9407 20.0083 49.6207 19.9169 49.3007 19.734C48.9807 19.5511 48.7064 19.3225 48.4778 19.0025C48.2949 18.6825 48.1578 18.2254 48.3407 17.8139C48.5235 17.4025 48.9807 17.1282 49.4379 17.0825C49.6664 17.0825 49.895 17.1282 50.1236 17.2196C50.3522 17.311 50.535 17.4939 50.6722 17.7225C50.9465 18.1339 50.9922 18.7282 50.8093 19.2311C50.6722 19.734 50.2607 20.1912 49.8036 20.4197C49.3007 20.694 48.7521 20.7855 48.2035 20.7855C47.0606 20.7398 45.9634 20.1912 45.0948 19.4597C44.1805 18.6825 43.4947 17.8139 42.8547 16.9453C42.2147 16.0767 41.5747 15.2538 40.8432 14.5681C40.1117 13.8823 39.2888 13.3794 38.466 13.1509C37.5973 12.9223 36.7745 12.9223 36.043 13.0137C34.5344 13.288 33.4829 13.9737 32.8429 14.5223C32.5228 14.7966 32.2943 15.0252 32.1114 15.1624C31.9742 15.2995 31.8828 15.391 31.8828 15.391C31.8828 15.391 31.8828 15.3452 31.9285 15.2995C31.9742 15.2538 32.02 15.2081 32.1114 15.1167C32.2485 14.9338 32.4771 14.7052 32.7971 14.4309C33.4372 13.8823 34.4886 13.1509 36.043 12.8766C36.7745 12.7394 37.6431 12.7394 38.5574 12.968C39.426 13.1966 40.2946 13.7452 41.0261 14.4309C41.7575 15.1167 42.3975 15.9853 43.0833 16.8539C43.7233 17.7225 44.4548 18.6368 45.3234 19.3683C46.192 20.0997 47.2435 20.6483 48.2949 20.694C48.8435 20.694 49.3464 20.6026 49.8036 20.374C50.2607 20.1454 50.5808 19.734 50.7636 19.2768C50.9008 18.8197 50.9008 18.3168 50.6265 17.9511C50.3979 17.5396 49.9407 17.4025 49.5293 17.4025C49.1178 17.4025 48.7064 17.631 48.5692 17.9968C48.4321 18.3625 48.5235 18.774 48.7064 19.094C48.8893 19.414 49.1636 19.6426 49.4836 19.7797C49.7579 19.9626 50.0779 20.054 50.3522 20.1454C50.9465 20.3283 51.4494 20.2826 51.8608 20.1454C52.2723 20.0083 52.5008 19.7797 52.638 19.5968C52.638 19.2768 52.6837 19.1397 52.6837 19.1397Z'
					fill='#375A64'
				/>
				<path
					d='M24.0629 17.4939C24.1087 17.4482 24.4287 17.8596 25.1144 18.1339C25.4801 18.2711 25.8916 18.4082 26.3945 18.4082C26.8973 18.4082 27.4459 18.3168 27.9945 18.1796C29.1374 17.8596 29.9146 17.0824 30.3718 16.4424C30.8289 15.8024 30.9661 15.3452 30.9661 15.3452C30.9661 15.3452 30.9661 15.4824 30.8747 15.6652C30.7832 15.8938 30.6918 16.1681 30.4632 16.4881C30.0518 17.1281 29.2289 17.951 28.0403 18.3168C27.4459 18.4996 26.8973 18.5911 26.3488 18.5454C25.8002 18.5454 25.343 18.4082 24.9773 18.2253C24.6115 18.0425 24.383 17.8596 24.2001 17.7225C24.1087 17.5853 24.0172 17.4939 24.0629 17.4939Z'
					fill='#375A64'
				/>
				<path
					d='M23.3789 20.2826C23.3789 20.2368 23.7904 20.4654 24.5218 20.6483C25.2533 20.8312 26.3047 20.9226 27.3562 20.6026C28.4534 20.2826 29.2763 19.6425 29.7792 19.0939C30.2821 18.5453 30.5106 18.1339 30.5564 18.1796C30.5564 18.1796 30.5106 18.3168 30.4192 18.4996C30.3278 18.6825 30.1449 18.9568 29.9163 19.2311C29.4135 19.8254 28.5448 20.5111 27.4477 20.8312C26.3047 21.1512 25.2076 21.0597 24.5218 20.8312C24.1561 20.7397 23.8818 20.6026 23.6989 20.5111C23.4703 20.374 23.3789 20.2826 23.3789 20.2826Z'
					fill='#375A64'
				/>
				<path
					d='M31.3336 28.8773C31.2879 28.8773 31.2422 28.8315 31.2422 28.7401C31.2422 28.6944 31.2879 28.6487 31.3336 28.6487C31.3793 28.6487 31.4251 28.6944 31.4251 28.7858C31.4251 28.8315 31.3793 28.8773 31.3336 28.8773Z'
					fill='#FF9A6C'
				/>
				<path
					d='M33.068 29.0144C33.0223 29.0144 32.9766 28.9686 32.9766 28.8772C32.9766 28.8315 33.0223 28.7401 33.068 28.7858C33.1137 28.7858 33.1594 28.8315 33.1594 28.9229C33.1594 28.9686 33.1137 29.0144 33.068 29.0144Z'
					fill='#FF9A6C'
				/>
				<path
					d='M34.1662 29.2887C34.1205 29.2887 34.0748 29.1973 34.1205 29.1058C34.1205 29.0144 34.2119 28.9687 34.2576 28.9687C34.3034 28.9687 34.3491 29.0601 34.3034 29.1516C34.2576 29.243 34.2119 29.3344 34.1662 29.2887Z'
					fill='#FF9A6C'
				/>
				<path
					d='M31.8336 30.1116C31.7879 30.1116 31.7422 30.0659 31.7422 30.0201C31.7422 29.9744 31.7879 29.9744 31.8336 29.9744C31.8793 29.9744 31.9251 30.0201 31.9251 30.0659C31.9251 30.1116 31.8793 30.1116 31.8336 30.1116Z'
					fill='#FF9A6C'
				/>
				<path
					d='M33.3883 30.2487C33.3426 30.2487 33.2969 30.1573 33.2969 30.1116C33.2969 30.0659 33.3426 30.0201 33.434 30.0201C33.4797 30.0201 33.5255 30.1116 33.5255 30.1573C33.5255 30.203 33.4797 30.2487 33.3883 30.2487Z'
					fill='#FF9A6C'
				/>
				<path
					d='M41.3883 31.7117C41.3426 31.7117 41.2969 31.666 41.2969 31.5745C41.2969 31.5288 41.3426 31.4831 41.3883 31.4831C41.434 31.4831 41.4797 31.5288 41.4797 31.6202C41.5255 31.666 41.434 31.7117 41.3883 31.7117Z'
					fill='#FF9A6C'
				/>
				<path
					d='M42.5328 31.9403C42.4871 31.9403 42.4414 31.8488 42.4414 31.8031C42.4414 31.7574 42.4871 31.7117 42.5786 31.7117C42.6243 31.7117 42.67 31.8031 42.67 31.8488C42.67 31.8945 42.5786 31.9403 42.5328 31.9403Z'
					fill='#FF9A6C'
				/>
				<path
					d='M40.4313 31.4374C40.3856 31.4374 40.3398 31.3917 40.3398 31.3459C40.3398 31.3002 40.3856 31.2545 40.4313 31.2545C40.477 31.2545 40.5227 31.3002 40.5227 31.3459C40.5227 31.3917 40.477 31.4374 40.4313 31.4374Z'
					fill='#FF9A6C'
				/>
				<path
					d='M40.8883 32.7631C40.8426 32.7631 40.7969 32.7174 40.7969 32.6717C40.7969 32.626 40.8426 32.5345 40.8883 32.5345C40.934 32.5345 40.9797 32.5803 40.9797 32.626C40.9797 32.7174 40.934 32.7631 40.8883 32.7631Z'
					fill='#FF9A6C'
				/>
				<path
					d='M41.8934 32.9003C41.8477 32.8545 41.8477 32.8088 41.8477 32.7631C41.8934 32.7174 41.9391 32.7174 41.9848 32.7631C42.0305 32.8088 42.0305 32.8545 42.0305 32.9003C41.9848 32.9003 41.9391 32.9003 41.8934 32.9003Z'
					fill='#FF9A6C'
				/>
				<path
					d='M8.2463 57.9071C11.8579 49.0381 20.544 41.0835 20.544 41.0835C20.544 41.0835 36.3618 50.5925 36.9104 50.9582C37.459 51.3239 55.334 63.9873 55.334 63.9873C55.334 63.9873 76.9121 64.7188 82.7181 64.5816L85.2782 75.8278C85.2782 75.8278 58.077 79.1651 54.5111 78.2965C52.6825 77.8394 34.8989 72.582 34.8989 72.582C34.8989 72.582 35.356 78.6165 35.6303 81.2681C36.0875 85.5654 38.739 91.7828 38.739 91.7828C38.739 91.7828 28.2243 102.892 26.6242 108.652C24.9784 114.458 17.8467 117.155 14.5094 117.018C11.1721 116.881 13.6865 114.595 13.6865 114.595C13.6865 114.595 8.56632 105.589 6.8291 94.9829C5.09188 84.3768 4.58901 66.776 8.2463 57.9071Z'
					fill='#FF4F5B'
				/>
				<path
					d='M17.0668 63.8045C17.0668 63.8045 17.0211 63.5302 16.8839 63.073C16.7468 62.6158 16.6096 61.9301 16.5182 61.1072C16.3353 59.4157 16.4268 56.9927 17.4325 54.6155C18.4383 52.1925 20.0384 50.4096 21.3641 49.3124C22.0041 48.7638 22.5985 48.3981 23.0099 48.1238C23.4214 47.8952 23.6499 47.7581 23.6499 47.7123C23.6499 47.7123 23.6042 47.7123 23.4671 47.8038C23.3756 47.8495 23.1928 47.9409 22.9642 48.0324C22.5527 48.2609 21.9584 48.6267 21.2727 49.1753C19.9012 50.2267 18.2554 52.0554 17.2497 54.4783C16.2439 56.947 16.1525 59.4157 16.3811 61.1072C16.4725 61.9758 16.6553 62.6616 16.7925 63.1187C16.8382 63.3473 16.9296 63.5302 16.9754 63.6216C17.0211 63.7587 17.0668 63.8045 17.0668 63.8045Z'
					fill='#1A2E35'
				/>
				<path
					d='M31.2863 70.2047C31.332 70.2047 31.1492 69.9304 31.012 69.3818C30.8749 68.8332 30.8749 68.0104 31.1949 67.096C31.5149 66.1817 32.2007 65.1759 33.2521 64.3988C34.3036 63.6216 35.6751 63.073 37.0923 62.6615C38.2352 62.2958 39.3781 62.0215 40.4296 61.6558C41.4811 61.2901 42.3954 60.8329 43.1726 60.2843C43.904 59.7357 44.4983 59.1414 44.8641 58.5928C45.2298 58.0442 45.4127 57.5413 45.5041 57.1756L45.4584 57.2213C45.8698 57.3127 46.0527 57.4042 46.0527 57.3585C46.0527 57.3585 45.8698 57.267 45.5041 57.1299H45.4584V57.1756C45.3669 57.4956 45.1384 57.9985 44.7726 58.5471C44.4069 59.0957 43.8583 59.6443 43.0811 60.1929C42.3497 60.6957 41.4353 61.1529 40.3839 61.4729C39.3324 61.8387 38.2352 62.113 37.0466 62.433C35.5837 62.8444 34.2122 63.4387 33.115 64.2159C32.0178 64.9931 31.2863 66.0445 31.012 67.0046C30.692 67.9646 30.7377 68.8332 30.9206 69.3818C31.012 69.6561 31.1035 69.839 31.1492 69.9762C31.2406 70.1133 31.2863 70.2047 31.2863 70.2047Z'
					fill='#FAFAFA'
				/>
				<path
					d='M29.9615 47.0266C29.9615 47.0266 29.87 47.0266 29.6872 47.0266C29.5957 47.0266 29.5043 47.0266 29.3672 47.0266C29.23 47.0266 29.0929 47.0723 28.91 47.0723C28.27 47.1637 27.3099 47.3923 26.3042 48.0323C25.2984 48.6266 24.2012 49.6324 23.5612 51.0953C22.8755 52.5582 22.8297 54.3412 23.1498 56.1241C23.4698 57.907 23.9727 59.5071 24.2927 61.0614C24.6127 62.5701 24.6584 63.9873 24.4755 65.1302C24.2927 66.2731 23.8812 67.1417 23.5155 67.6903C23.1498 68.2389 22.8297 68.4675 22.8755 68.4675C22.8755 68.4675 23.1955 68.2846 23.6069 67.736C24.0184 67.2331 24.4755 66.3188 24.6584 65.1759C24.887 63.9873 24.8413 62.5701 24.5212 61.0614C24.2012 59.5528 23.6984 57.907 23.3783 56.1698C23.0583 54.4326 23.1498 52.6954 23.7898 51.2782C24.4298 49.861 25.4813 48.9009 26.4413 48.2609C27.4014 47.6209 28.3614 47.3923 29.0014 47.2551C29.1386 47.2094 29.3215 47.2094 29.4129 47.2094C29.55 47.2094 29.6415 47.2094 29.7329 47.1637C29.87 47.0265 29.9615 47.0266 29.9615 47.0266Z'
					fill='#FAFAFA'
				/>
				<path
					d='M9.70703 55.5298C9.70703 55.5755 10.1642 55.5298 10.8499 55.6212C11.5357 55.6669 12.4957 55.8498 13.4558 56.2612C14.4158 56.6727 15.2387 57.2213 15.7416 57.6327C16.2902 58.0899 16.5645 58.4099 16.5645 58.3642C16.5645 58.3642 16.3359 57.9985 15.833 57.4956C15.3301 56.9927 14.5072 56.4441 13.5472 55.987C12.5414 55.5755 11.5814 55.4384 10.8499 55.3926C10.1185 55.3926 9.70703 55.4841 9.70703 55.5298Z'
					fill='#FAFAFA'
				/>
				<path
					d='M16.4761 45.5179C16.4304 45.5179 16.5218 45.838 16.7961 46.3408C17.0247 46.8437 17.4818 47.4837 18.0761 48.0781C18.6704 48.6724 19.3105 49.1295 19.8134 49.3581C20.3162 49.6324 20.6362 49.7238 20.6362 49.6781C20.682 49.6324 19.4019 49.0838 18.259 47.8952C17.0704 46.7523 16.5675 45.5179 16.4761 45.5179Z'
					fill='#FAFAFA'
				/>
			</g>
			<rect x='64.5' y='14.5' width='55' height='9' fill='#999999' />
			<rect x='10.5' y='64.5' width='109' height='5' fill='#999999' />
			<rect x='64.5' y='28.5' width='33' height='4' fill='#999999' />
			<rect
				x='119.5'
				y='41.5'
				width='33'
				height='4'
				transform='rotate(-180 119.5 41.5)'
				fill='#999999'
			/>
			<rect x='101.5' y='28.5' width='18' height='4' fill='#999999' />
			<rect
				x='82.5'
				y='41.5'
				width='18'
				height='4'
				transform='rotate(-180 82.5 41.5)'
				fill='#999999'
			/>
		</svg>
	)
}
