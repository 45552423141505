import React from 'react'

export default function() {
	return (
		<svg width='69' height='76' viewBox='0 0 69 76' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clip-path='url(#clip0)'>
				<path
					d='M18.9558 75.8614C20.2224 61.1106 23.431 31.9694 29.4541 25.0931C29.4541 25.0931 10.8498 32.4408 4.01044 58.1161C1.87137 66.1847 1.05514 71.8687 0.970703 75.8614H18.9558Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M68.7178 75.8614C68.6334 71.8687 67.8453 66.1846 65.6781 58.116C58.8387 32.4407 40.2344 25.0653 40.2344 25.0653C46.2857 31.9416 49.4662 61.1106 50.7327 75.8337H68.7178V75.8614Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M57.3172 75.8614C57.3172 75.3069 57.3172 74.7246 57.3172 74.1701C57.3172 46.1657 47.3254 23.4849 35.0258 23.4849C22.7261 23.4849 12.7344 46.1657 12.7344 74.1701C12.7344 74.7246 12.7344 75.3069 12.7344 75.8614H57.3172Z'
					fill='#FF3333'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M26.7509 27.0894C18.5324 34.5757 12.7344 52.8202 12.7344 74.1701C12.7344 74.7246 12.7344 75.3069 12.7344 75.8614H26.4695L26.7509 27.0894Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M26.7513 27.0894C26.1884 27.6162 25.6255 28.1985 25.0626 28.8085L19.0957 46.0825L22.6984 46.8589L23.4301 75.8614H26.498L26.7513 27.0894Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M45.0718 75.8613H57.3152C57.3152 75.3068 57.3152 74.7245 57.3152 74.17C57.3152 53.4855 51.855 35.6847 44.0586 27.8102L45.0718 75.8613Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M43.2715 27.0339V75.8336H46.3957L47.1274 46.8311L50.7301 46.0547L44.5943 28.3648C44.2003 27.9212 43.7218 27.4498 43.2715 27.0339Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M46.6524 36.7108C46.2865 33.4944 45.3014 30.2781 43.5 27.5331C42.0365 25.3149 39.9818 23.3463 37.3643 22.5145C35 21.7659 32.4106 22.0986 30.2716 23.3463C27.8792 24.7327 26.106 27.0063 24.9239 29.4463C23.2914 32.8013 22.6159 36.5999 22.7004 40.3153C22.7004 40.7589 23.404 40.7589 23.404 40.3153C23.3196 37.0989 23.8262 33.8549 25.0365 30.8881C26.0216 28.4481 27.5696 26.0913 29.7368 24.5386C31.6225 23.1799 33.9868 22.4868 36.2947 22.9859C38.7716 23.5127 40.8543 25.1763 42.3179 27.1449C44.4007 29.9176 45.4984 33.3281 45.8924 36.7385C46.005 37.1544 46.7086 37.1544 46.6524 36.7108Z'
					fill='#262433'
				/>
				<path
					d='M23.601 44.8072C24.9534 44.8072 26.0497 43.7396 26.0497 42.4226C26.0497 41.1057 24.9534 40.0381 23.601 40.0381C22.2487 40.0381 21.1523 41.1057 21.1523 42.4226C21.1523 43.7396 22.2487 44.8072 23.601 44.8072Z'
					fill='#C0C0C0'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M22.2773 42.4226C22.2773 43.1435 22.8684 43.6981 23.6002 43.6981C24.332 43.6981 24.923 43.1158 24.923 42.4226C24.923 41.7017 24.332 41.1472 23.6002 41.1472C22.8684 41.1472 22.2773 41.7294 22.2773 42.4226Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M50.5916 45.5834C50.9575 43.8921 50.9294 42.0898 50.4509 40.4262C50.085 39.1785 49.4377 37.903 48.3118 37.1544C47.3549 36.4889 46.1446 36.4335 45.1314 36.988C43.9493 37.598 43.1612 38.818 42.6827 39.9825C41.9228 41.8125 41.7539 43.8921 42.0917 45.833C42.1761 46.2766 42.8516 46.0825 42.7671 45.6389C42.4857 44.0307 42.5983 42.3671 43.1049 40.8144C43.4708 39.7053 44.0618 38.5407 45.0469 37.8198C45.835 37.2376 46.8483 37.0712 47.7208 37.5426C48.7059 38.0971 49.2969 39.1507 49.6628 40.1766C50.2258 41.8403 50.282 43.6425 49.9162 45.3616C49.8036 45.833 50.4791 45.9993 50.5916 45.5834Z'
					fill='#262433'
				/>
				<path
					d='M50.845 51.7943C50.845 51.7943 48.9874 53.9016 46.1165 52.1548C43.2457 50.408 42.4576 47.0252 42.2324 45.0844'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M50.4785 51.4893C50.4785 51.822 50.7318 52.0716 51.0696 52.0716C51.4073 52.0716 51.6606 51.822 51.6606 51.4893C51.6606 51.1566 51.4073 50.907 51.0696 50.907C50.7318 50.907 50.4785 51.1566 50.4785 51.4893Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M41.584 51.7666C41.584 51.7666 43.4135 53.9016 46.3125 52.1825C49.2115 50.4635 50.0277 47.1085 50.281 45.1676'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M41.979 51.4617C41.979 51.7944 41.6975 52.0439 41.3879 52.0439C41.0502 52.0439 40.7969 51.7667 40.7969 51.4617C40.7969 51.1289 41.0783 50.8794 41.3879 50.8794C41.7257 50.8794 41.979 51.1289 41.979 51.4617Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M39.0798 0.27733C39.0798 0.27733 30.5798 -2.16266 25.7669 7.5141C22.0798 14.9172 23.2056 20.1022 23.9092 22.1263C24.1063 22.6808 24.6129 23.069 25.1758 23.069L45.1029 23.6236C45.7503 23.6513 46.3132 23.2076 46.4821 22.5699C47.6079 18.4386 50.6476 3.90958 39.0798 0.27733Z'
					fill='#262433'
				/>
				<path
					d='M26.7793 13.8359C26.7793 15.4718 28.1303 16.8027 29.7909 16.8027C31.4515 16.8027 32.8025 15.4718 32.8025 13.8359C32.8025 12.2 31.4515 10.8691 29.7909 10.8691C28.1303 10.8691 26.7793 12.2 26.7793 13.8359Z'
					stroke='#262433'
					stroke-width='2'
					stroke-miterlimit='10'
				/>
				<path
					d='M39.9506 4.65826H36.1509C33.6178 4.65826 31.5631 6.98734 31.5631 9.81551V15.6105C31.5631 15.7768 31.5631 15.9432 31.5913 16.0818C31.5631 16.2482 31.535 16.4423 31.535 16.6086V19.7973C31.5068 19.9359 31.4787 20.0745 31.4787 20.2132L31.4506 24.2613C30.2966 24.6218 29.1426 25.1763 28.0449 25.8695L34.9688 32.2745L40.5979 25.2318C39.6691 24.7327 38.7122 24.3722 37.7552 24.1227C37.7552 24.0395 37.7552 23.9563 37.7552 23.8731V20.7677H39.9506C42.4837 20.7677 44.5383 18.4386 44.5383 15.6105V9.81551C44.5383 6.98734 42.4837 4.65826 39.9506 4.65826Z'
					fill='#F4F4F4'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M37.7838 12.5328C38.2502 12.5328 38.6282 12.1604 38.6282 11.701C38.6282 11.2416 38.2502 10.8691 37.7838 10.8691C37.3175 10.8691 36.9395 11.2416 36.9395 11.701C36.9395 12.1604 37.3175 12.5328 37.7838 12.5328Z'
					fill='#262433'
				/>
				<path
					d='M42.541 11.59C42.541 11.9782 42.8788 12.311 43.2728 12.311C43.6668 12.311 44.0046 11.9782 44.0046 11.59C44.0046 11.2019 43.6668 10.8691 43.2728 10.8691C42.8788 10.8691 42.541 11.1741 42.541 11.59Z'
					fill='#262433'
				/>
				<path
					d='M41.0496 11.2297C41.0496 12.0892 41.0215 12.921 41.0215 13.7806C41.0215 13.8915 41.0215 14.0301 41.0215 14.141C41.0215 14.3628 41.3592 14.3628 41.3874 14.141C41.3874 13.2815 41.4155 12.4496 41.4155 11.5901C41.4155 11.4792 41.4155 11.3406 41.4155 11.2297C41.3874 11.0078 41.0496 11.0078 41.0496 11.2297Z'
					fill='#262433'
				/>
				<path
					d='M36.6876 10.5641C36.9972 10.4532 37.3349 10.3146 37.6445 10.2037C37.7008 10.176 37.729 10.176 37.7853 10.1482C37.8697 10.1205 37.926 10.0373 37.8978 9.92641C37.8697 9.84323 37.7853 9.76005 37.6727 9.8155C37.3631 9.92641 37.0253 10.065 36.7157 10.176C36.6594 10.2037 36.6313 10.2037 36.575 10.2314C36.4905 10.2591 36.4343 10.3423 36.4624 10.4532C36.4905 10.5087 36.6031 10.5919 36.6876 10.5641Z'
					fill='#262433'
				/>
				<path
					d='M42.6825 10.37C43.0203 10.37 43.358 10.3423 43.6958 10.3423C43.7521 10.3423 43.7802 10.3423 43.8365 10.3423C43.921 10.3423 44.0054 10.2591 44.0054 10.1759C44.0054 10.0928 43.921 10.0096 43.8365 10.0096C43.4988 10.0096 43.161 10.0373 42.8233 10.0373C42.767 10.0373 42.7388 10.0373 42.6825 10.0373C42.5981 10.0373 42.5137 10.1205 42.5137 10.2037C42.5137 10.2869 42.5981 10.37 42.6825 10.37Z'
					fill='#262433'
				/>
				<path
					d='M45.2696 9.39957C45.2696 9.39957 41.9203 8.37366 39.9219 5.2405C39.9219 5.2405 38.1206 9.92638 32.1819 12.5605C24.4137 15.9986 28.2978 6.62686 30.4368 4.2146C32.5759 1.80234 43.5809 -1.21991 45.2696 9.39957Z'
					fill='#262433'
				/>
				<path
					d='M26.7793 13.8359C26.7793 15.4718 28.1303 16.8027 29.7909 16.8027C31.4515 16.8027 32.8025 15.4718 32.8025 13.8359C32.8025 12.2 31.4515 10.8691 29.7909 10.8691C28.1303 10.8691 26.7793 12.2 26.7793 13.8359Z'
					fill='#F4F4F4'
				/>
				<path
					d='M31.6201 14.2241C31.6764 13.5032 31.3105 12.6991 30.635 12.3941C30.2691 12.2278 29.8469 12.2 29.4529 12.2278C29.3403 12.2278 29.2559 12.3109 29.2559 12.4218C29.2559 12.505 29.3403 12.6159 29.4529 12.6159C29.7906 12.5882 30.0721 12.5882 30.3817 12.6991C30.438 12.7268 30.3817 12.6991 30.438 12.7268C30.4661 12.7268 30.4943 12.7546 30.5224 12.7546C30.5787 12.7823 30.635 12.81 30.6913 12.8655C30.6631 12.8377 30.7194 12.8932 30.7194 12.8932C30.7476 12.9209 30.7476 12.9209 30.7757 12.9487C30.8039 12.9764 30.8602 13.0318 30.8883 13.0596C30.8602 13.0318 30.9165 13.0873 30.9165 13.0873C30.9446 13.115 30.9446 13.115 30.9446 13.1427C30.9727 13.1982 31.0009 13.2259 31.029 13.2814C31.029 13.3091 31.0572 13.3368 31.0572 13.3646C31.0572 13.3923 31.029 13.3091 31.0572 13.3646V13.3923C31.0853 13.4477 31.0853 13.4755 31.1135 13.5309C31.1416 13.6141 31.1698 13.6973 31.1698 13.7805C31.1698 13.8082 31.1698 13.8082 31.1698 13.8359C31.1698 13.8914 31.1698 13.8082 31.1698 13.8359C31.1698 13.8636 31.1698 13.9191 31.1698 13.9468C31.1698 14.03 31.1698 14.1132 31.1698 14.1686C31.2261 14.4736 31.6201 14.4736 31.6201 14.2241Z'
					fill='#262433'
				/>
				<path
					d='M27.8223 26.0081L35.1964 33.7162L41.5855 25.8417C40.713 25.2872 39.8405 24.8435 38.9398 24.5108L35.1964 29.973L30.2428 24.7049C29.4266 25.0653 28.6103 25.4813 27.8223 26.0081Z'
					fill='#F47070'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M43.639 13.6972C41.0777 12.2554 39.9519 13.8358 37.1936 14.6399C35.4767 15.139 34.6042 14.5845 34.2101 14.1131C34.0694 13.9467 33.8161 14.0299 33.788 14.224C33.6754 15.0004 33.5346 16.359 33.5909 18.0504C33.6191 19.2703 35.8707 20.1299 37.7565 20.8508V20.7676H39.9519C42.485 20.7676 44.5396 18.4385 44.5396 15.6104V14.2517C44.2863 14.0854 44.0048 13.8913 43.639 13.6972Z'
					fill='white'
					stroke='#262433'
					stroke-miterlimit='10'
				/>
				<path
					d='M32.3514 12.2277C32.7455 12.7545 33.1677 13.2813 33.5617 13.8081C33.618 13.8913 33.6743 13.9468 33.7306 14.0299C33.7869 14.1131 33.8713 14.1408 33.9839 14.0854C34.0683 14.0299 34.0965 13.919 34.0402 13.8359C33.6461 13.309 33.224 12.7822 32.8299 12.2554C32.7736 12.1722 32.7173 12.1168 32.661 12.0336C32.6048 11.9504 32.5203 11.9227 32.4077 11.9781C32.3514 12.0336 32.2952 12.1445 32.3514 12.2277Z'
					fill='#262433'
				/>
				<path
					d='M31.7055 17.5513C32.3247 16.9135 32.9157 16.2481 33.535 15.6104C33.6194 15.5272 33.7038 15.4163 33.7883 15.3331C33.929 15.1667 33.7038 14.9172 33.535 15.0836C32.9157 15.7213 32.3247 16.3867 31.7055 17.0244C31.6211 17.1076 31.5366 17.2185 31.4522 17.3017C31.2833 17.4681 31.5366 17.7176 31.7055 17.5513Z'
					fill='#262433'
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='68' height='76' fill='white' transform='translate(0.857422)' />
				</clipPath>
			</defs>
		</svg>
	)
}
