import React from 'react'

interface IProps {
	width: string
	height: string
}
export default function(props: IProps) {
	const { width = '106', height = '76' } = props
	return (
		<svg
			viewBox='0 0 106 76'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
			width={width}
			height={height}
		>
			<path
				opacity='0.9'
				d='M68.7162 11.2307L54.7695 66.8287L91.4211 76L105.368 20.402L68.7162 11.2307Z'
				fill='#E5E5E5'
			/>
			<path
				d='M79.2258 29.9023C82.4808 28.3333 83.8454 24.4271 82.2738 21.1775C80.7021 17.928 76.7893 16.5657 73.5343 18.1347C70.2793 19.7037 68.9147 23.6099 70.4864 26.8595C72.058 30.109 75.9708 31.4713 79.2258 29.9023Z'
				fill='#748A96'
			/>
			<path
				d='M81.4857 24.9404C82.0017 22.1264 80.1351 19.4276 77.3163 18.9124C74.4976 18.3972 71.7942 20.2607 71.2782 23.0747C70.7621 25.8887 72.6288 28.5876 75.4475 29.1028C78.2662 29.618 80.9696 27.7544 81.4857 24.9404Z'
				fill='white'
			/>
			<path
				d='M79.2677 34.3494L67.6094 31.4325L66.9498 34.0599L78.6081 36.9767L79.2677 34.3494Z'
				fill='#748A96'
			/>
			<path
				d='M68.5191 55.0806L62.0781 53.4691L59.5208 63.6559L65.9618 65.2674L68.5191 55.0806Z'
				fill='#748A96'
			/>
			<path
				d='M91.273 60.7576L84.832 59.1461L82.2747 69.3329L88.7157 70.9444L91.273 60.7576Z'
				fill='#748A96'
			/>
			<path
				d='M79.8979 57.9302L73.457 56.3187L70.8998 66.5055L77.3406 68.117L79.8979 57.9302Z'
				fill='#748A96'
			/>
			<path
				d='M88.7039 36.7058L80.8086 34.7304L80.149 37.3578L88.0444 39.3332L88.7039 36.7058Z'
				fill='#748A96'
			/>
			<path
				d='M95.0404 44.3397L66.1602 37.1139L65.8882 38.1971L94.7685 45.4229L95.0404 44.3397Z'
				fill='white'
			/>
			<path
				d='M79.9648 43.0028L65.6055 39.4101L65.3335 40.4933L79.6928 44.086L79.9648 43.0028Z'
				fill='white'
			/>
			<path
				d='M92.0099 46.0143L82.4062 43.6115L82.1343 44.6947L91.738 47.0975L92.0099 46.0143Z'
				fill='white'
			/>
			<path
				d='M88.1131 47.4677L65.0273 41.6917L64.7554 42.7749L87.8412 48.5509L88.1131 47.4677Z'
				fill='white'
			/>
			<path
				d='M90.9861 50.6311L64.4375 43.9887L64.1656 45.0719L90.7141 51.7143L90.9861 50.6311Z'
				fill='white'
			/>
			<path
				d='M66.9532 47.0578L63.8828 46.2896L63.6109 47.3728L66.6813 48.141L66.9532 47.0578Z'
				fill='white'
			/>
			<path
				d='M82.9537 51.0518L69.4023 47.6613L69.1304 48.7445L82.6817 52.135L82.9537 51.0518Z'
				fill='white'
			/>
			<path
				d='M92.185 55.7976L63.3047 48.5718L63.0328 49.655L91.913 56.8808L92.185 55.7976Z'
				fill='white'
			/>
			<path
				d='M48.3216 11.7773C43.0381 4.53051 35.3746 1.15662 27.3779 1.15662C27.3779 1.15662 7.79073 1.15662 1.9836 18.6676C-0.158375 25.1303 0.317622 32.1157 2.91179 38.412C3.81618 40.5742 5.00616 42.9501 6.57695 45.2073C11.6225 52.5016 27.3779 72.4123 27.3779 72.4123H30.8051C35.208 66.3298 46.2511 46.0864 49.583 39.6C53.7242 31.4504 54.3668 20.0932 48.3216 11.7773ZM30.6385 44.1619C21.4042 44.1619 13.9073 36.6776 13.9073 27.4587C13.9073 18.2399 21.4042 10.7556 30.6385 10.7556C39.8727 10.7556 47.3697 18.2399 47.3697 27.4587C47.3697 36.6776 39.8965 44.1619 30.6385 44.1619Z'
				fill='#FF4F5B'
			/>
			<path
				opacity='0.4'
				d='M48.3216 11.7773C43.0381 4.53051 35.3746 1.15662 27.3779 1.15662C27.3779 1.15662 7.79073 1.15662 1.9836 18.6676C-0.158375 25.1303 0.317622 32.1157 2.91179 38.412C3.81618 40.5742 5.00616 42.9501 6.57695 45.2073C11.6225 52.5016 27.3779 72.4123 27.3779 72.4123H30.8051C35.208 66.3298 46.2511 46.0864 49.583 39.6C53.7242 31.4504 54.3668 20.0932 48.3216 11.7773ZM30.6385 44.1619C21.4042 44.1619 13.9073 36.6776 13.9073 27.4587C13.9073 18.2399 21.4042 10.7556 30.6385 10.7556C39.8727 10.7556 47.3697 18.2399 47.3697 27.4587C47.3697 36.6776 39.8965 44.1619 30.6385 44.1619Z'
				fill='black'
			/>
			<path
				d='M51.7474 11.9198C46.4877 4.67303 38.8004 1.29913 30.8037 1.29913C30.8037 1.29913 11.2165 1.29913 5.40938 18.8101C3.26741 25.2728 3.7434 32.2582 6.33757 38.5545C7.24196 40.7167 8.43194 43.0927 10.0027 45.3499C15.0483 52.6441 30.8037 72.5548 30.8037 72.5548C30.8037 72.5548 48.9867 47.5833 52.985 39.7425C57.1738 31.5692 57.8163 20.212 51.7474 11.9198ZM30.6609 44.2807C21.379 44.3044 13.9059 36.8914 13.9297 27.5775C13.9535 18.3587 21.4266 10.8743 30.6609 10.8743C39.8952 10.8743 47.3921 18.3587 47.3921 27.5775C47.3921 36.8438 39.8952 44.2807 30.6609 44.2807Z'
				fill='#FF4F5B'
			/>
			<path
				d='M30.6386 10.7556C23.3559 10.7556 17.1679 15.3887 14.8594 21.8752C17.1204 16.9093 22.1183 13.4642 27.9254 13.4642C35.8508 13.4642 42.2529 19.8793 42.2529 27.7676C42.2529 35.6796 35.827 42.071 27.9254 42.071C23.3321 42.071 19.2385 39.9089 16.6206 36.5587C19.5955 41.1206 24.7601 44.1619 30.6386 44.1619C39.8729 44.1619 47.3698 36.6775 47.3698 27.4587C47.3698 18.2161 39.8967 10.7556 30.6386 10.7556Z'
				fill='#FF4F5B'
			/>
			<path
				opacity='0.4'
				d='M30.6386 10.7556C23.3559 10.7556 17.1679 15.3887 14.8594 21.8752C17.1204 16.9093 22.1183 13.4642 27.9254 13.4642C35.8508 13.4642 42.2529 19.8793 42.2529 27.7676C42.2529 35.6796 35.827 42.071 27.9254 42.071C23.3321 42.071 19.2385 39.9089 16.6206 36.5587C19.5955 41.1206 24.7601 44.1619 30.6386 44.1619C39.8729 44.1619 47.3698 36.6775 47.3698 27.4587C47.3698 18.2161 39.8967 10.7556 30.6386 10.7556Z'
				fill='black'
			/>
			<path
				d='M64.0007 35.1094C63.3343 34.6579 62.5014 34.4441 61.6922 34.5392C60.3118 34.7055 58.3364 34.8955 58.3364 34.8955V34.9668L48.7451 35.5608L45.8177 29.5971L41.3672 32.5196C41.3672 32.5196 41.9622 33.4462 44.4612 38.0556C45.4607 39.8851 47.5075 40.9305 49.5781 40.6217L60.883 38.9822V38.9585C62.0492 38.8872 63.3105 38.7922 63.5009 38.6258C63.8579 38.317 64.1673 37.1527 64.1673 37.1527C64.1673 37.1527 66.1903 38.3407 66.5711 38.3645C66.9519 38.3882 67.0233 38.0794 67.2613 37.723C67.4041 37.4854 65.3335 36.0123 64.0007 35.1094Z'
				fill='#FFBF9D'
			/>
			<path
				d='M46.4148 29.2169C46.4148 29.2169 44.5584 25.6292 42.8448 22.2553C40.7505 18.0973 36.1095 17.2657 36.1095 17.2657L29.398 16.3866C29.398 16.3866 23.805 13.8443 18.5691 20.1406L14.2852 24.9164L15.1896 25.7242L19.4497 29.5495L20.2827 30.3098L22.2105 28.3378L21.3061 33.3511L19.7353 40.3365L23.1387 39.9326L25.4472 25.843L38.4419 26.8884L37.4185 35.7746C37.6327 35.7508 39.2035 35.442 39.7033 33.3036C39.8937 32.472 39.8461 31.7592 39.7033 31.2365L40.4649 33.161L46.4148 29.2169Z'
				fill='#375A64'
			/>
			<path
				d='M25.4463 25.8192L23.1377 39.9088L19.7344 40.3127C22.6617 42.8313 26.4697 44.3519 30.6585 44.3519C32.7528 44.3519 34.7282 43.9717 36.5846 43.2827L37.4414 35.7271L38.4648 26.8409L25.4463 25.8192Z'
				fill='#375A64'
			/>
			<path
				d='M13.9261 26.5796C13.9023 26.8647 13.9023 27.1498 13.9023 27.4349C13.9023 28.5754 14.0213 29.7159 14.2355 30.7851V30.8088C14.2593 30.9514 14.3069 31.0939 14.3307 31.2127C14.4497 31.7592 14.6163 32.2819 14.7829 32.8046C14.8067 32.8759 14.8305 32.9234 14.8543 32.9947C15.4731 32.8522 17.2581 31.3078 19.3049 29.407L15.0685 25.6054C14.4735 26.2232 14.1165 26.532 13.9261 26.5796Z'
				fill='#FFBF9D'
			/>
			<path
				d='M40.7513 1.91687C40.8941 3.5563 42.7743 4.93437 44.4165 4.88685C45.3209 4.86309 46.2491 4.45917 47.1296 4.69677C48.6052 5.12445 49.1764 7.16779 50.6996 7.42915C51.8182 7.64299 52.8654 6.71635 54.0078 6.66884C54.8408 6.62132 55.6262 7.04899 56.3401 7.52419C57.0541 7.99939 57.6967 8.54586 58.4821 8.80722C59.2675 9.06858 60.0053 8.59338 60.8145 8.35578C61.0049 8.85474 61.1477 9.49625 61.4095 10.0427C61.6713 10.5892 61.9807 11.5396 61.5285 11.7534C61.5285 11.7534 60.2671 12.4187 59.5293 12.3712C60.1005 12.9652 61.0287 13.2265 61.8379 12.9889C62.0521 14.2007 62.1949 15.6263 61.3619 16.5292C61.0049 16.9331 60.4813 17.1944 59.9339 17.3132C57.2921 18.0023 54.5076 16.149 51.8182 16.648C50.7234 16.8618 49.7 17.4558 48.5814 17.5984C47.4866 17.7409 46.3443 17.4083 45.4637 16.7192C44.3927 15.8639 43.6787 14.4145 42.3221 14.2007C41.7271 14.1057 41.1083 14.272 40.5371 14.4383C39.9421 14.6046 39.3471 14.7709 38.7522 14.6284C38.1572 14.5096 37.6098 13.9869 37.6336 13.3929'
				fill='#1A2E35'
			/>
			<path
				d='M35.4554 17.8597C35.741 16.1965 36.098 14.4621 36.098 14.4621C36.098 14.4621 38.2876 14.4858 39.2395 11.7772C40.1201 9.18737 40.7627 2.98605 40.7627 2.98605C37.9068 1.20407 34.1226 1.39415 31.4808 3.46125L29.2912 16.9568C29.0294 18.525 30.1718 19.9981 31.8616 20.2357C33.5752 20.497 35.1698 19.4279 35.4554 17.8597Z'
				fill='#FFBF9D'
			/>
			<path
				d='M39.1228 7.643C39.099 7.83308 38.9086 7.97564 38.7182 7.95188C38.5278 7.92812 38.385 7.7618 38.4088 7.57172C38.4326 7.38164 38.623 7.23908 38.8134 7.26284C39.0276 7.2866 39.1466 7.45292 39.1228 7.643Z'
				fill='#1A2E35'
			/>
			<path
				d='M39.7837 7.33406C39.7361 7.38158 39.4981 7.12023 39.1173 7.04895C38.7365 6.97767 38.4271 7.12023 38.3795 7.07271C38.3557 7.04895 38.4271 6.97767 38.5699 6.90639C38.7127 6.83511 38.9269 6.78759 39.1649 6.83511C39.4029 6.88263 39.5933 7.00143 39.6885 7.12023C39.7837 7.23902 39.8075 7.33406 39.7837 7.33406Z'
				fill='#1A2E35'
			/>
			<path
				d='M35.1697 7.02533C35.1459 7.21541 34.9555 7.35796 34.7651 7.3342C34.5747 7.31045 34.4319 7.14413 34.4557 6.95405C34.4795 6.76397 34.6699 6.62141 34.8603 6.64517C35.0745 6.64517 35.1935 6.83525 35.1697 7.02533Z'
				fill='#1A2E35'
			/>
			<path
				d='M35.7485 6.78768C35.7009 6.8352 35.4629 6.57384 35.0821 6.50256C34.7013 6.43128 34.392 6.57384 34.3444 6.52632C34.3206 6.50256 34.392 6.43128 34.5347 6.36C34.6775 6.28872 34.8917 6.2412 35.1297 6.28872C35.3677 6.33624 35.5581 6.45504 35.6533 6.57384C35.7485 6.66888 35.7723 6.76392 35.7485 6.78768Z'
				fill='#1A2E35'
			/>
			<path
				d='M36.625 9.87637C36.625 9.85261 36.863 9.85261 37.2676 9.87637C37.3628 9.87637 37.458 9.87637 37.4818 9.80509C37.5056 9.73381 37.5056 9.61502 37.458 9.49622C37.4104 9.23486 37.3628 8.9735 37.2914 8.68838C37.0772 7.54791 36.9344 6.62128 36.982 6.62128C37.0296 6.62128 37.2438 7.52415 37.458 8.66462C37.5056 8.94974 37.5532 9.2111 37.6008 9.47246C37.6246 9.59126 37.6484 9.73381 37.577 9.87637C37.5294 9.94765 37.458 9.99517 37.3866 9.99517C37.3152 9.99517 37.2676 9.99517 37.22 9.99517C36.8868 9.94765 36.625 9.90013 36.625 9.87637Z'
				fill='#1A2E35'
			/>
			<path
				d='M36.1012 14.462C36.1012 14.462 34.1972 14.2482 32.5312 12.8226C32.5312 12.8226 33.1024 14.8897 35.9346 15.1273L36.1012 14.462Z'
				fill='#FF9A6C'
			/>
			<path
				d='M36.3119 10.7792C36.2167 10.5654 35.9787 10.4229 35.7407 10.4229C35.5741 10.4229 35.3837 10.4704 35.2647 10.5892C35.1457 10.708 35.0743 10.898 35.1457 11.0406C35.2171 11.2069 35.4313 11.2782 35.6217 11.2544C35.8121 11.2307 36.0025 11.1356 36.1691 11.0406C36.2167 11.0168 36.2643 10.9931 36.2881 10.9456C36.3357 10.898 36.3357 10.8268 36.3119 10.7792Z'
				fill='#FF9A6C'
			/>
			<path
				d='M35.4606 9.94762C35.532 9.94762 35.4606 10.3753 35.7462 10.7317C36.0556 11.0881 36.5078 11.1119 36.5078 11.1831C36.5078 11.2069 36.3888 11.2544 36.1984 11.2069C36.008 11.1831 35.7462 11.0643 35.5558 10.8505C35.3654 10.6367 35.3178 10.3753 35.3416 10.209C35.3654 10.0189 35.4368 9.92386 35.4606 9.94762Z'
				fill='#1A2E35'
			/>
			<path
				d='M33.3906 1.46543C34.1284 3.10486 36.556 5.10069 38.4124 5.81348C38.2458 5.48085 37.1986 4.53045 37.032 4.17406C37.7936 4.98189 38.8646 6.0986 40.0307 6.38372C40.1735 6.40748 40.3163 6.455 40.4353 6.40748C40.6019 6.3362 40.6733 6.16988 40.7209 6.00356C40.9351 5.33829 41.1017 4.17406 41.1255 3.46126C41.1493 2.77223 40.9589 2.03567 40.4591 1.46543C39.8165 0.75264 38.7932 0.420003 37.7936 0.206164C36.9606 0.0398453 36.08 -0.0789538 35.247 0.0636051C34.414 0.229924 33.6524 0.705121 33.4144 1.39416'
				fill='#1A2E35'
			/>
			<path
				d='M28.9375 7.66668C28.9375 6.93013 28.9613 6.21733 29.0089 5.7659C29.1993 4.31655 29.6515 -0.958133 34.5304 0.396177L34.0068 1.75049C34.0068 1.75049 34.3876 5.14814 31.8887 6.95389C31.8887 6.95389 31.8887 10.3278 30.1751 11.5633C30.1513 11.5395 28.9375 9.25859 28.9375 7.66668Z'
				fill='#1A2E35'
			/>
			<path
				d='M31.9907 6.7163C31.7051 6.5975 31.3957 6.54998 31.0863 6.62126C30.7769 6.66878 30.4913 6.8351 30.3247 7.09646C30.1581 7.35781 30.1105 7.69045 30.1581 7.97557C30.2057 8.28445 30.3485 8.56956 30.5389 8.80716C30.6579 8.99724 30.8245 9.16356 31.0149 9.2586C31.2053 9.35364 31.4671 9.3774 31.6575 9.28236'
				fill='#FFBF9D'
			/>
			<path
				d='M31.4917 8.49843C31.5155 8.52219 31.3727 8.66475 31.1109 8.54595C30.9919 8.49843 30.8729 8.35587 30.8253 8.18956C30.7777 8.02324 30.7539 7.85692 30.7539 7.66684C30.7777 7.47676 30.8491 7.28668 30.9681 7.16788C31.0871 7.04908 31.2775 6.9778 31.3965 7.00156C31.6821 7.04908 31.7297 7.26292 31.7059 7.26292C31.6821 7.28668 31.5869 7.16788 31.3965 7.16788C31.2061 7.16788 30.9919 7.38172 30.9919 7.6906C30.9681 7.99948 31.0395 8.30835 31.1823 8.40339C31.3489 8.52219 31.4917 8.47467 31.4917 8.49843Z'
				fill='#FF9A6C'
			/>
			<path
				d='M60.1682 6.95395C60.1682 6.95395 60.1444 7.02523 60.0254 7.14403C59.9302 7.26283 59.7398 7.40539 59.4542 7.47667C59.1686 7.54795 58.7878 7.54795 58.407 7.40539C58.0024 7.26283 57.6216 6.97771 57.217 6.66884C56.8124 6.35996 56.3603 6.00356 55.7653 5.861C55.4797 5.81348 55.1703 5.81348 54.8609 5.90852C54.5515 5.9798 54.2421 6.12236 53.9565 6.28868C53.6471 6.43124 53.3377 6.62132 53.0045 6.76388C52.6713 6.90643 52.3143 7.02523 51.9335 7.04899C51.5527 7.07275 51.1481 6.95395 50.8149 6.71636C50.4817 6.50252 50.2199 6.19364 49.9819 5.861C49.5059 5.21949 49.1251 4.53045 48.5301 4.10277C48.2445 3.86518 47.9113 3.74638 47.5544 3.69886C47.1974 3.65134 46.8642 3.6751 46.531 3.74638C45.8646 3.86518 45.2458 4.07901 44.627 4.15029C44.0082 4.22157 43.437 4.15029 42.9372 4.00774C41.9376 3.69886 41.2712 3.12862 40.8904 2.67718C40.5096 2.22575 40.3906 1.91687 40.3906 1.91687C40.3906 1.91687 40.4144 1.94063 40.4144 1.96439C40.4382 2.01191 40.462 2.05943 40.5096 2.10695C40.581 2.22575 40.7238 2.41583 40.9142 2.62967C41.295 3.05734 41.9614 3.60382 42.961 3.88894C43.4608 4.03149 44.032 4.10277 44.627 4.00774C45.222 3.93646 45.8408 3.72262 46.531 3.58006C46.8642 3.50878 47.2212 3.48502 47.6019 3.53254C47.9589 3.58006 48.3397 3.72262 48.6253 3.96022C49.2441 4.43541 49.6249 5.12445 50.1009 5.7422C50.3389 6.05108 50.6007 6.35996 50.9101 6.55004C51.2195 6.76388 51.5765 6.85891 51.9573 6.85891C52.3143 6.85891 52.6713 6.74012 52.9807 6.5738C53.3139 6.43124 53.6233 6.26492 53.9327 6.0986C54.2421 5.95604 54.5515 5.81348 54.8609 5.71844C55.1703 5.6234 55.5035 5.6234 55.8129 5.69468C56.4317 5.83724 56.8839 6.2174 57.2884 6.52628C57.693 6.85891 58.0738 7.12027 58.4546 7.28659C58.8354 7.45291 59.1924 7.45291 59.478 7.38163C59.7636 7.31035 59.954 7.19155 60.0492 7.07275C60.1444 7.02523 60.1682 6.93019 60.1682 6.95395Z'
				fill='#1A2E35'
			/>
		</svg>
	)
}
