import React from 'react'
import { Grid, ColCard, Span, Arrow } from '../styles'
import IconTypeCpf from '@cuidardigital/commons/assets/icons/iconTypeCpf'
import IconTypeCnpj from '@cuidardigital/commons/assets/icons/iconTypeCnpj'

const Step1 = ({ data, onChange, type }) => {
	const handleType = (type: string) => () => onChange(type)
	const renderIcon = (type: string) => (type === 'cpf' ? <IconTypeCpf /> : <IconTypeCnpj />)

	return (
		<>
			<Grid>
				{data.options.map(item => (
					<ColCard
						key={`col-step1-${item.value}`}
						active={type === item.value}
						size='6'
						flex
						alingItems='center'
						justifyContent='center'
						onClick={handleType(item.value)}
						padding='15px 24px'
					>
						{renderIcon(item.value)}
						<Span
							fontSize='20px/20px'
							bold
							color={type === item.value && 'white'}
							padding='0 10px 10px'
							wrap
						>
							{item.title}
						</Span>
						<Arrow active={type === item.value} />
					</ColCard>
				))}
			</Grid>
		</>
	)
}

export default Step1
