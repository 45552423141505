import React from 'react'

interface IProps {
	width: string
	height: string
	active: boolean
}
export default function(props: IProps) {
	const { width = '14', height = '15', active } = props
	const color = active ? '#2760E3' : 'white'
	return (
		<svg
			viewBox='0 0 14 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
			width={width}
			height={height}
		>
			<g clip-path='url(#clip0)'>
				<path
					d='M12.5067 6.94587L7.14586 12.3067C6.48912 12.9634 5.59838 13.3324 4.66961 13.3324C3.74084 13.3324 2.8501 12.9634 2.19336 12.3067C1.53662 11.65 1.16766 10.7592 1.16766 9.83045C1.16766 8.90168 1.53662 8.01094 2.19336 7.3542L7.55419 1.99337C7.99202 1.55554 8.58584 1.30957 9.20503 1.30957C9.82421 1.30957 10.418 1.55554 10.8559 1.99337C11.2937 2.4312 11.5397 3.02502 11.5397 3.6442C11.5397 4.26338 11.2937 4.85721 10.8559 5.29503L5.48919 10.6559C5.27028 10.8748 4.97337 10.9978 4.66378 10.9978C4.35418 10.9978 4.05727 10.8748 3.83836 10.6559C3.61945 10.437 3.49646 10.14 3.49646 9.83045C3.49646 9.52086 3.61945 9.22395 3.83836 9.00503L8.79086 4.05837'
					stroke={color}
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='14' height='14' fill={color} transform='translate(0 0.5)' />
				</clipPath>
			</defs>
		</svg>
	)
}
