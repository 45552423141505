import React from 'react'

interface IProps {
	width: string
	height: string
}
export default function(props: IProps) {
	const { width = '113', height = '76' } = props
	return (
		<svg
			viewBox='0 0 113 76'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
			width={width}
			height={height}
		>
			<path
				d='M9.00221 43.19L10.3475 42.8389C10.3475 42.8389 10.9324 40.6736 11.1956 37.3965C11.5465 33.0074 9.85032 29.701 7.71542 28.3258C5.20034 26.6872 3.88431 28.1795 4.03053 29.3499C4.17676 30.5788 5.52203 32.3345 6.16543 33.5341C7.07203 35.2897 8.4758 37.9232 9.00221 43.19Z'
				fill='#00DFBF'
			/>
			<path
				opacity='0.2'
				d='M9.00221 43.19L10.3475 42.8389C10.3475 42.8389 10.9324 40.6736 11.1956 37.3965C11.5465 33.0074 9.85032 29.701 7.71542 28.3258C5.20034 26.6872 3.88431 28.1795 4.03053 29.3499C4.17676 30.5788 5.52203 32.3345 6.16543 33.5341C7.07203 35.2897 8.4758 37.9232 9.00221 43.19Z'
				fill='black'
			/>
			<path
				d='M9.67255 43.1315C9.6433 43.1315 9.6433 43.1315 9.67255 43.1315C9.58481 43.1315 9.55557 43.0729 9.58481 43.0144C11.1933 35.2019 7.88859 29.6425 5.84142 28.5306C5.78293 28.5013 5.78293 28.4428 5.81218 28.3843C5.84142 28.3258 5.89991 28.3258 5.9584 28.355C8.12255 29.5254 11.4565 35.0556 9.81877 43.0729C9.76028 43.1022 9.70179 43.1315 9.67255 43.1315Z'
				fill='white'
			/>
			<path
				d='M9.0056 43.19C9.0056 43.19 9.21032 41.0248 8.44994 38.8595C7.68957 36.7235 6.49051 34.7045 4.06316 33.973C1.66506 33.2415 -0.235883 34.9971 0.963171 36.5772C2.16222 38.1865 6.57825 39.1814 7.45561 44.2141L9.0056 43.19Z'
				fill='#00DFBF'
			/>
			<path
				d='M8.3329 43.746C8.27441 43.746 8.21592 43.6874 8.24516 43.6289C8.5961 39.591 5.8763 35.5531 2.27914 34.8508C2.22065 34.8508 2.19141 34.7923 2.19141 34.7338C2.19141 34.6753 2.2499 34.646 2.30839 34.646C5.99328 35.3775 8.80082 39.4739 8.42063 43.6289C8.42063 43.7167 8.39139 43.746 8.3329 43.746Z'
				fill='white'
			/>
			<path
				d='M86.9336 54.7021C86.9336 54.7021 87.4015 50.3716 90.0921 47.4456C92.8119 44.5195 95.6779 44.5488 96.8185 45.6022C97.9298 46.6555 96.7892 48.2941 94.2741 49.8742C92.11 51.2201 89.2732 54.3217 88.3959 56.3992L86.9336 54.7021Z'
				fill='#00DFBF'
			/>
			<path
				opacity='0.2'
				d='M86.9336 54.7021C86.9336 54.7021 87.4015 50.3716 90.0921 47.4456C92.8119 44.5195 95.6779 44.5488 96.8185 45.6022C97.9298 46.6555 96.7892 48.2941 94.2741 49.8742C92.11 51.2201 89.2732 54.3217 88.3959 56.3992L86.9336 54.7021Z'
				fill='black'
			/>
			<path
				d='M87.544 55.5214C87.4563 55.4922 87.427 55.4629 87.4563 55.4044C88.5968 50.9861 92.4572 46.5093 95.7327 45.8655C95.7912 45.8655 95.8496 45.8948 95.8496 45.9533C95.8496 46.0118 95.8204 46.0704 95.7619 46.0704C92.5742 46.7141 88.8016 51.1031 87.661 55.4629C87.6317 55.4922 87.6025 55.5214 87.544 55.5214Z'
				fill='white'
			/>
			<path
				d='M87.4019 64.8847C87.4019 64.8847 89.3906 65.4406 90.8529 64.4751C92.0812 63.6265 91.7887 62.7194 91.9349 62.0172C92.0812 61.3149 92.6953 60.9346 93.6896 60.8175C94.9179 60.642 96.0877 60.4664 96.2925 59.1497C96.4972 57.9793 94.567 57.3648 95.1519 56.2529C95.5906 55.4044 96.5557 54.8192 97.3453 54.2047C98.1349 53.561 97.667 52.1857 95.7661 52.1857C93.3972 52.1857 92.9293 53.0635 91.8764 53.2098C90.8236 53.3561 90.2387 52.7417 89.0981 52.888C87.8406 53.0635 87.1972 54.2632 87.168 55.7847L87.4019 64.8847Z'
				fill='#00DFBF'
			/>
			<path
				d='M87.2867 60.5249H87.2575C87.199 60.4956 87.1697 60.4371 87.199 60.4078C88.2518 57.4525 93.8961 53.3268 96.2943 52.9172C96.3527 52.9172 96.4112 52.9464 96.4112 53.005C96.4112 53.0635 96.382 53.122 96.3235 53.122C93.9839 53.5316 88.4273 57.5988 87.4037 60.4956C87.3452 60.5248 87.316 60.5249 87.2867 60.5249Z'
				fill='white'
			/>
			<path
				d='M95.0069 58.7985C94.9776 58.7985 94.9776 58.7985 94.9484 58.7985C93.4861 58.0377 90.971 57.4818 89.0701 57.8914C89.0116 57.8914 88.9531 57.8622 88.9531 57.8037C88.9531 57.7451 88.9824 57.6866 89.0409 57.6866C91.0003 57.277 93.5446 57.8329 95.0654 58.5937C95.1239 58.6229 95.1239 58.6815 95.0946 58.74C95.0946 58.7692 95.0361 58.7985 95.0069 58.7985Z'
				fill='white'
			/>
			<path
				d='M90.0922 56.8674C90.0337 56.8674 90.0045 56.8089 90.0045 56.7796C90.0045 55.024 89.4195 54.1462 89.3903 54.1462C89.3611 54.0876 89.3611 54.0291 89.4196 53.9999C89.478 53.9706 89.5365 53.9706 89.5658 54.0291C89.595 54.0584 90.2092 54.9362 90.2092 56.7796C90.2092 56.8089 90.1507 56.8674 90.0922 56.8674Z'
				fill='white'
			/>
			<path
				d='M82.1222 5.29453L82.2976 5.20674C82.5901 5.03118 83.0288 5.03118 83.3212 5.20674L112.011 21.7388C112.303 21.9144 112.508 22.2947 112.508 22.6166V62.6154C112.508 62.9373 112.274 63.3176 112.011 63.4932L111.835 63.581C111.543 63.7565 111.104 63.7565 110.812 63.581L82.1222 47.0197C81.8297 46.8441 81.625 46.4637 81.625 46.1419V6.14307C81.625 5.85047 81.859 5.44083 82.1222 5.29453Z'
				fill='#FAFAFA'
			/>
			<path
				d='M112.507 42.6306V22.6166C112.507 22.441 112.448 22.2654 112.36 22.1191L110.723 23.0555V63.5517L110.81 63.6102C111.103 63.7858 111.542 63.7858 111.834 63.6102L112.009 63.5224C112.302 63.3469 112.507 62.9665 112.507 62.6446V42.6306Z'
				fill='#E1E1E1'
			/>
			<path
				d='M82.1222 5.87967L110.812 22.441C111.104 22.6165 111.309 22.9969 111.309 23.3188V63.3176C111.309 63.6394 111.075 63.7857 110.812 63.6102L82.1222 47.0489C81.8297 46.8733 81.625 46.4929 81.625 46.1711V6.17227C81.625 5.85041 81.859 5.70411 82.1222 5.87967Z'
				fill='#F5F5F5'
			/>
			<path
				d='M85.6886 10.6784L107.242 23.1433C107.535 23.3189 107.739 23.6993 107.739 24.0211V25.6012C107.739 25.923 107.506 26.0693 107.242 25.8938L85.6886 13.4289C85.3961 13.2533 85.1914 12.873 85.1914 12.5511V10.971C85.1914 10.6492 85.3961 10.5029 85.6886 10.6784Z'
				fill='#E1E1E1'
			/>
			<path
				d='M90.247 24.659C91.4079 23.9884 91.4063 21.811 90.2434 19.7958C89.0805 17.7806 87.1966 16.6906 86.0357 17.3613C84.8747 18.0319 84.8763 20.2092 86.0392 22.2244C87.2022 24.2397 89.086 25.3296 90.247 24.659Z'
				fill='#E1E1E1'
			/>
			<path
				d='M88.1778 18.6957C87.5929 18.3738 87.125 18.6372 87.125 19.3102C87.125 19.9831 87.5929 20.7732 88.1778 21.1243C88.7627 21.4462 89.2307 21.1828 89.2307 20.5098C89.2307 19.8368 88.7627 19.0176 88.1778 18.6957Z'
				fill='#00DFBF'
			/>
			<path
				d='M88.4393 21.5924L87.8544 21.2413C86.9185 20.6853 86.1289 21.1242 86.1289 22.2361V22.3824C86.6553 23.2602 87.3572 23.9918 88.1468 24.4599C88.9364 24.8988 89.6383 24.9573 90.1647 24.7233V24.577C90.1647 23.4651 89.4044 22.1191 88.4393 21.5924Z'
				fill='#00DFBF'
			/>
			<path
				d='M85.8075 25.8352L90.4867 28.5271C90.8376 28.7319 91.1301 29.2001 91.1301 29.5805C91.1301 29.9609 90.8376 30.1072 90.4867 29.9024L85.8075 27.2104C85.4565 27.0056 85.1641 26.5374 85.1641 26.157C85.1933 25.7767 85.4565 25.6304 85.8075 25.8352Z'
				fill='#E1E1E1'
			/>
			<path
				d='M85.8075 28.5857L90.4867 31.2777C90.8376 31.4825 91.1301 31.9507 91.1301 32.331C91.1301 32.7114 90.8376 32.8577 90.4867 32.6529L85.8075 29.961C85.4565 29.7561 85.1641 29.288 85.1641 28.9076C85.1933 28.5272 85.4565 28.3809 85.8075 28.5857Z'
				fill='#E1E1E1'
			/>
			<path
				d='M85.8075 31.3362L90.4867 34.0281C90.8376 34.2329 91.1301 34.7011 91.1301 35.0815C91.1301 35.4619 90.8376 35.6082 90.4867 35.4033L85.8075 32.7114C85.4565 32.5066 85.1641 32.0384 85.1641 31.658C85.1933 31.2776 85.4565 31.1313 85.8075 31.3362Z'
				fill='#E1E1E1'
			/>
			<path
				d='M85.8075 34.0866L90.4867 36.7785C90.8376 36.9834 91.1301 37.4515 91.1301 37.8319C91.1301 38.2123 90.8376 38.3586 90.4867 38.1538L85.8075 35.4618C85.4565 35.257 85.1641 34.7888 85.1641 34.4084C85.1933 34.0281 85.4565 33.8818 85.8075 34.0866Z'
				fill='#E1E1E1'
			/>
			<path
				d='M85.8075 38.183L90.4867 40.8749C90.8376 41.0798 91.1301 41.5772 91.1301 41.9868V44.6495C91.1301 45.0592 90.8376 45.2055 90.4867 45.0299L85.8075 42.3379C85.4565 42.1331 85.1641 41.6357 85.1641 41.2261V38.5634C85.1933 38.1537 85.4565 37.9782 85.8075 38.183Z'
				fill='#00DFBF'
			/>
			<path
				d='M95.3114 20.3636L107.126 27.1812C107.477 27.386 107.77 27.8542 107.77 28.2346C107.77 28.615 107.477 28.7613 107.126 28.5565L95.3114 21.7095C94.9604 21.5047 94.668 21.0366 94.668 20.6562C94.6972 20.3051 94.9604 20.1588 95.3114 20.3636Z'
				fill='#00DFBF'
			/>
			<path
				d='M95.3114 23.0848L107.126 29.9024C107.477 30.1072 107.77 30.5754 107.77 30.9558C107.77 31.3362 107.477 31.4825 107.126 31.2776L95.3114 24.46C94.9604 24.2552 94.668 23.787 94.668 23.4066C94.6972 23.0262 94.9604 22.8799 95.3114 23.0848Z'
				fill='#00DFBF'
			/>
			<path
				d='M95.3114 25.8352L107.126 32.6529C107.477 32.8577 107.77 33.3259 107.77 33.7063C107.77 34.0866 107.477 34.2329 107.126 34.0281L95.3114 27.2105C94.9604 27.0057 94.668 26.5375 94.668 26.1571C94.6972 25.7767 94.9604 25.6304 95.3114 25.8352Z'
				fill='#00DFBF'
			/>
			<path
				d='M79.2416 75.6817L86.202 71.6438C86.9039 71.2341 87.4595 70.2685 87.4595 69.4492V29.8308C87.4595 29.0115 86.9039 28.046 86.202 27.6363L38.8247 0.307233C38.1228 -0.102411 36.9823 -0.102411 36.2804 0.307233L29.32 4.31589C28.6182 4.72553 28.0625 5.69112 28.0625 6.51041V46.1288C28.0625 46.9481 28.6182 47.9137 29.32 48.3233L76.668 75.6817C77.3992 76.0913 78.5397 76.0913 79.2416 75.6817Z'
				fill='#375A64'
			/>
			<path
				d='M87.4577 29.8601C87.4577 29.4504 87.3114 29.0115 87.0775 28.6019L76.7539 34.571V75.7402C77.4558 76.0913 78.5379 76.0913 79.2105 75.7109L86.1709 71.673C86.8727 71.2633 87.4284 70.2977 87.4284 69.4785L87.4577 29.8601Z'
				fill='#2B454E'
			/>
			<path
				d='M76.6973 33.1373L29.32 5.77895C28.6182 5.3693 28.0625 5.69117 28.0625 6.51045V46.1289C28.0625 46.9481 28.6182 47.9137 29.32 48.3234L76.668 75.6817C77.3699 76.0914 77.9256 75.7695 77.9256 74.9502V35.3318C77.9548 34.5418 77.3992 33.5469 76.6973 33.1373Z'
				fill='#2B454E'
			/>
			<path
				d='M80.8535 30.7379C81.5554 31.1475 82.1111 32.1131 82.1111 32.9324V74.0138L83.3101 73.3408V32.2594C83.3101 31.4401 82.7545 30.4745 82.0526 30.0649L33.4178 1.97504L32.2188 2.64802L80.8535 30.7379Z'
				fill='#1A2E35'
			/>
			<path
				d='M82.0488 30.0649L33.5017 2.03357L32.2734 2.70656L80.8497 30.7671C81.2007 30.972 81.5224 31.3231 81.7563 31.7035L82.9554 31.0305C82.6922 30.6208 82.3997 30.2697 82.0488 30.0649Z'
				fill='#2B454E'
			/>
			<path
				d='M74.6811 23.7447L73.833 23.2473C73.482 23.0425 72.9264 23.0425 72.5754 23.2473L69.3584 25.0907L68.832 24.9151V25.5881C68.832 25.7344 68.9198 25.8515 69.0952 25.9685L70.2066 26.6122C70.5575 26.8171 71.1132 26.8171 71.4641 26.6122L74.9443 24.5933C75.1197 24.5055 75.2075 24.3592 75.2075 24.2422V23.5692L74.6811 23.7447Z'
				fill='#1A2E35'
			/>
			<path
				d='M74.9443 23.218L73.833 22.5743C73.482 22.3694 72.9264 22.3694 72.5754 22.5743L69.0952 24.5932C68.7443 24.7981 68.7443 25.1199 69.0952 25.3247L70.2066 25.9685C70.5575 26.1733 71.1132 26.1733 71.4641 25.9685L74.9443 23.9495C75.2952 23.7447 75.2952 23.4228 74.9443 23.218Z'
				fill='#375A64'
			/>
			<path
				d='M46.1655 7.27116L45.3173 6.77373C44.9664 6.56891 44.4107 6.56891 44.0598 6.77373L40.8428 8.61713L40.3164 8.44157V9.11456C40.3164 9.26086 40.4041 9.3779 40.5796 9.49494L41.6909 10.1387C42.0419 10.3435 42.5975 10.3435 42.9485 10.1387L46.4287 8.11971C46.6041 8.03193 46.6919 7.88562 46.6919 7.76858V7.0956L46.1655 7.27116Z'
				fill='#1A2E35'
			/>
			<path
				d='M46.4287 6.74449L45.3173 6.10076C44.9664 5.89594 44.4107 5.89594 44.0598 6.10076L40.5796 8.11972C40.2287 8.32454 40.2287 8.6464 40.5796 8.85122L41.6909 9.49495C42.0419 9.69977 42.5975 9.69977 42.9485 9.49495L46.4287 7.47599C46.7796 7.27117 46.7796 6.94931 46.4287 6.74449Z'
				fill='#375A64'
			/>
			<path
				d='M55.3767 7.12482V11.2505L53.0078 12.6258V6.3348C53.0078 6.01293 53.2418 5.89589 53.505 6.04219L55.3767 7.12482Z'
				fill='#1A2E35'
			/>
			<path
				d='M67.8389 11.4847V19.1508L66.0841 20.175L64.8851 19.4727V13.211C64.8851 12.8891 64.6511 12.4795 64.3879 12.3332L55.3804 7.12488L53.5087 6.04225C53.2163 5.89594 53.0115 6.01299 53.0115 6.33485V12.6258L51.8125 11.9236V4.25737C51.8125 4.22811 51.8125 4.19885 51.8125 4.16959C51.8417 3.87699 52.0757 3.52586 52.3097 3.37956L53.07 2.94066C53.3625 2.79436 53.8012 2.79436 54.0936 2.94066L67.3417 10.5776C67.6341 10.7824 67.8389 11.1628 67.8389 11.4847Z'
				fill='#375A64'
			/>
			<path
				d='M66.0842 12.5088V20.175L64.8851 19.4727V13.211C64.8851 12.8892 64.6511 12.4795 64.3879 12.3332L55.3804 7.1249L53.5087 6.04227C53.2163 5.89597 53.0116 6.01301 53.0116 6.33488V12.6258L51.8125 11.9236V4.2574C51.8125 4.22814 51.8125 4.19888 51.8125 4.16962C51.8417 3.93553 52.0757 3.81849 52.3097 3.96479L65.5577 11.6017C65.8502 11.7773 66.0842 12.1869 66.0842 12.5088Z'
				fill='#2B454E'
			/>
			<path
				d='M0.873186 46.5256C10.1439 40.9369 15.8175 30.9007 15.8175 20.0744V9.68698C15.8175 9.36512 16.0515 9.21882 16.3147 9.39438L42.6353 24.5805C42.9278 24.756 43.1325 25.1364 43.1325 25.4583V35.8457C43.1325 46.6427 37.4882 56.6497 28.3052 62.2384C27.925 62.4725 27.4571 62.4725 27.0769 62.2384L0.873186 47.1109C0.60998 46.9646 0.60998 46.7012 0.873186 46.5256Z'
				fill='white'
			/>
			<path
				d='M27.6902 62.5603C27.4563 62.5603 27.2223 62.5018 26.9883 62.3847L0.78464 47.2572C0.609168 47.1401 0.492188 46.9938 0.492188 46.8475C0.492188 46.672 0.579921 46.5257 0.755393 46.4086C9.93839 40.8784 15.612 30.7836 15.612 20.0744V9.687C15.612 9.48218 15.6997 9.30661 15.8459 9.21883C15.9922 9.13105 16.1676 9.16031 16.3431 9.2481L42.6638 24.4342C42.9855 24.6097 43.2487 25.0486 43.2487 25.429V35.8164C43.2487 46.5842 37.5459 56.7375 28.3629 62.3262C28.1582 62.5018 27.9242 62.5603 27.6902 62.5603ZM0.960111 46.6427C0.872375 46.7012 0.813884 46.7597 0.813884 46.8183C0.813884 46.8768 0.872375 46.9353 0.960111 46.9938L27.1638 62.1214C27.5148 62.3262 27.9242 62.3262 28.2459 62.1214C37.3412 56.5912 43.0147 46.5257 43.0147 35.8457V25.4583C43.0147 25.1949 42.81 24.8438 42.576 24.6975L16.2554 9.51144C16.1676 9.45292 16.0799 9.45292 16.0506 9.48218C15.9922 9.51144 15.9629 9.56996 15.9629 9.687V20.0744C15.9629 30.8714 10.2016 41.0833 0.960111 46.6427Z'
				fill='#E1E1E1'
			/>
			<path
				d='M31.9012 22.503L38.9493 26.5701C39.3003 26.775 39.5927 27.2431 39.5927 27.6235C39.5927 28.0039 39.3003 28.1502 38.9493 27.9454L31.9012 23.8782C31.5503 23.6734 31.2578 23.2052 31.2578 22.8248C31.2578 22.4444 31.5503 22.2981 31.9012 22.503Z'
				fill='#E1E1E1'
			/>
			<path
				d='M27.6904 26.2483C27.6904 26.6286 27.3979 26.7749 27.047 26.5701L19.9989 22.503C19.6479 22.2981 19.3555 21.83 19.3555 21.4496C19.3555 21.0692 19.6479 20.9229 19.9989 21.1277L27.047 25.1949C27.3979 25.3997 27.6904 25.8679 27.6904 26.2483Z'
				fill='#E1E1E1'
			/>
			<path
				d='M39.2116 38.3913C39.1531 38.7424 38.8314 38.8594 38.4805 38.6546L19.5588 27.7113C19.2079 27.5065 18.9739 27.0675 19.0324 26.7164C19.0909 26.3653 19.4126 26.2483 19.7635 26.4531L38.6852 37.3964C39.0361 37.6012 39.2701 38.0401 39.2116 38.3913Z'
				fill='#E1E1E1'
			/>
			<path
				d='M38.4553 42.0488C38.3675 42.3707 37.9873 42.4877 37.6364 42.2829L18.7148 31.3395C18.3638 31.1347 18.1591 30.7251 18.2468 30.3739C18.3346 30.0521 18.6855 29.935 19.0364 30.1399L37.9581 41.0832C38.309 41.3173 38.543 41.7269 38.4553 42.0488Z'
				fill='#E1E1E1'
			/>
			<path
				d='M37.2523 45.5015C37.1354 45.7941 36.7259 45.8819 36.375 45.6771L17.4533 34.7338C17.1024 34.5289 16.9269 34.1193 17.0439 33.8267C17.1609 33.5341 17.5411 33.417 17.892 33.6219L36.8137 44.5652C37.1938 44.77 37.3986 45.1797 37.2523 45.5015Z'
				fill='#E1E1E1'
			/>
			<path
				d='M35.7335 48.7201C35.5873 49.0127 35.1486 49.0712 34.7976 48.8664L15.876 37.9231C15.525 37.7183 15.3788 37.3379 15.525 37.0453C15.6713 36.7527 16.0807 36.6941 16.4316 36.899L35.3533 47.8423C35.7335 48.0471 35.8797 48.4275 35.7335 48.7201Z'
				fill='#E1E1E1'
			/>
			<path
				d='M24.7946 47.9009C24.5899 48.1643 24.1512 48.2228 23.8003 48.018L13.1843 41.8733C12.8333 41.6685 12.7163 41.2881 12.921 41.0248C13.1258 40.7614 13.5644 40.7029 13.9154 40.9077L24.5314 47.0524C24.8823 47.2572 24.9993 47.6376 24.7946 47.9009Z'
				fill='#E1E1E1'
			/>
			<path
				d='M20.9003 53.5775C20.9003 53.5775 15.3437 57.235 8.67578 57.4983C10.0211 58.2884 13.0918 58.6102 15.4607 58.2884C15.4607 58.2884 15.139 59.6636 12.7409 61.4192C12.7409 61.4192 16.6305 62.4726 23.6201 55.1282L20.9003 53.5775Z'
				fill='#00DFBF'
			/>
			<path
				d='M26.778 54.9527C25.579 56.1524 23.1809 56.2694 21.5431 55.3038C19.9054 54.3675 19.6422 52.7289 20.8412 51.5C22.0403 50.3003 24.2044 49.9785 25.8422 50.9148C27.5092 51.8804 27.9771 53.7531 26.778 54.9527Z'
				fill='#00DFBF'
			/>
			<path
				opacity='0.2'
				d='M26.778 54.9527C25.579 56.1524 23.1809 56.2694 21.5431 55.3038C19.9054 54.3675 19.6422 52.7289 20.8412 51.5C22.0403 50.3003 24.2044 49.9785 25.8422 50.9148C27.5092 51.8804 27.9771 53.7531 26.778 54.9527Z'
				fill='black'
			/>
			<path
				d='M26.778 54.5723C25.579 55.7719 23.1809 55.889 21.5431 54.9234C19.9054 53.9871 19.6422 52.3485 20.8412 51.1196C22.0403 49.9199 24.2044 49.598 25.8422 50.5344C27.5092 51.5292 27.9771 53.3726 26.778 54.5723Z'
				fill='#00DFBF'
			/>
			<path
				d='M24.0295 54.7186C23.2984 54.7186 22.5673 54.543 21.9824 54.1919C21.3975 53.87 21.0465 53.4019 20.988 52.963C20.9295 52.4655 21.222 52.0559 21.456 51.7925C21.9824 51.2366 22.8305 50.9147 23.7371 50.9147C24.3512 50.9147 24.9361 51.061 25.4041 51.3536C26.0475 51.734 26.4569 52.29 26.5446 52.8459C26.6031 53.2556 26.4569 53.6652 26.1352 53.9871C25.6965 54.4552 24.9361 54.7186 24.0295 54.7186Z'
				fill='#00DFBF'
			/>
			<path
				opacity='0.3'
				d='M24.0295 54.7186C23.2984 54.7186 22.5673 54.543 21.9824 54.1919C21.3975 53.87 21.0465 53.4019 20.988 52.963C20.9295 52.4655 21.222 52.0559 21.456 51.7925C21.9824 51.2366 22.8305 50.9147 23.7371 50.9147C24.3512 50.9147 24.9361 51.061 25.4041 51.3536C26.0475 51.734 26.4569 52.29 26.5446 52.8459C26.6031 53.2556 26.4569 53.6652 26.1352 53.9871C25.6965 54.4552 24.9361 54.7186 24.0295 54.7186Z'
				fill='black'
			/>
			<path
				d='M25.4317 51.7048C24.9345 51.4122 24.3496 51.2659 23.7647 51.2659C22.8873 51.2659 22.0392 51.5877 21.4836 52.1437C21.2788 52.3485 21.0741 52.6411 21.0156 52.9922C21.1034 53.4311 21.4543 53.87 22.01 54.1919C22.5949 54.5138 23.326 54.7186 24.0571 54.7186C24.9637 54.7186 25.7241 54.4552 26.192 53.9871C26.426 53.753 26.543 53.4897 26.6015 53.197C26.4845 52.6411 26.0458 52.0559 25.4317 51.7048Z'
				fill='#00DFBF'
			/>
			<path
				opacity='0.2'
				d='M25.4317 51.7048C24.9345 51.4122 24.3496 51.2659 23.7647 51.2659C22.8873 51.2659 22.0392 51.5877 21.4836 52.1437C21.2788 52.3485 21.0741 52.6411 21.0156 52.9922C21.1034 53.4311 21.4543 53.87 22.01 54.1919C22.5949 54.5138 23.326 54.7186 24.0571 54.7186C24.9637 54.7186 25.7241 54.4552 26.192 53.9871C26.426 53.753 26.543 53.4897 26.6015 53.197C26.4845 52.6411 26.0458 52.0559 25.4317 51.7048Z'
				fill='black'
			/>
		</svg>
	)
}
