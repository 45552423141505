import React from 'react'

export default function(props) {
	const n = Math.floor(Math.random() * 100)
	const color = props.color || '#333333'
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' {...props}>
			<defs>
				<style>{`.a-arrow{fill:none;}.b-arrow-${n}{fill:${color};}`}</style>
			</defs>
			<rect className='a-arrow' width='24' height='24' />
			<path
				className={`b-arrow-${n}`}
				d='M6.863,0,0,7l6.863,7L8.4,12.537,2.971,7,8.4,1.463Z'
				transform='translate(5 16.4) rotate(-90)'
			/>
		</svg>
	)
}
