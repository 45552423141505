import { Loader } from '@cuidardigital/commons/components/Loader'
import FinancialRegister from '@cuidardigital/commons/assets/icons/financialRegister'
import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import IModalMessage from '@cuidardigital/commons/types/IModalMessage'
import { postSellerDocs, postDoctorAddress } from '@cuidardigital/commons/services/doctor.services'
import SEO from '@cuidardigital/commons/components/Seo/seo'
import { Button, Col, Grid, Span, Checkbox, Logo, StepNumber } from './styles'
import { HOME, FINANCIAL } from '../../routes'
import { Step1, Step2, Step3 } from './stepsEmpty'
import logo from '../../assets/img/logo.png'
import MessageModal from '../Modal'
import { store } from '../../stores/app'
import { initUserSession } from '../../utils/session'
import TemplatePage from '../../templates/Page'

const cosmic = {
	step0: {
		title: 'Pagar consultas ficou ainda mais fácil!',
		paragraph: `
	<p>Agora seus pacientes poderão pagar suas consultas de forma digital. Basta você selecionar as formas de pagamento que deseja disponibilizar e pronto! A melhor parte é que, assim, você pode controlar ainda melhor tudo da sua área financeira aqui no Cuidar Digital.</p>
<p>Mas antes de fazer isso, precisamos de algumas informações, tudo bem?</p>
	`,
		buttonAccept: 'Cadastrar agora',
		buttonDecline: 'Lembre-me depois',
		notPerturb: 'Não mostrar novamente'
	},
	step1: {
		title: 'Você atende como pessoa física ou jurídica?',
		paragraph: `
	<p>Escolha se você deseja se cadastrar como pessoa física ou jurídica</p>
	`,
		buttonAccept: 'Avançar',
		buttonDecline: 'Voltar',
		options: [
			{ value: 'cpf', title: 'Sou pessoa física' },
			{ value: 'cnpj', title: 'Sou pessoa jurídica' }
		]
	},
	step2: {
		title: 'Vamos confirmar alguns dados?',
		paragraph: `
	<p>Confirme seus dados cadastrados e, se precisar, fique à vontade para atualizar.</p>
	`,
		buttonAccept: 'Avançar',
		buttonDecline: 'Voltar'
	},
	step3: {
		title: 'Para finalizar, precisamos de alguns documentos.',
		paragraph: `
	<p>Anexe os documentos abaixo para finalizarmos seu cadastro! Não esqueça de que eles devem estar bem legíveis e no nome do titular.</p>
	`,
		buttonAccept: 'Avançar',
		buttonDecline: 'Voltar',
		options: [
			{
				title: 'Documento de identidade com foto',
				paragraph: 'Comprovante de atividade exercida',
				button: 'Anexar documento',
				value: 'identity'
			},
			{
				title: 'Comprovante de residência',
				paragraph: 'Pode estar em seu nome ou de familiares.',
				button: 'Anexar documento',
				value: 'residence'
			},
			{
				title: 'Comprovante de atividade exercida',
				paragraph:
					'Aqui você pode inserir seu cartão de visita, notas fiscais de compra ou prestação de serviço, entre outros.',
				button: 'Anexar documento',
				value: 'work-activity'
			}
		]
	},
	step4: {
		title: 'Parabéns, seu cadastro já está em análise!',
		paragraph: `
	<p>Agora, todos os seus dados e documentos estão sendo validados, mas não se preocupe! Vamos te informar por email quanto estiver tudo certo, ok?</p>
<p>Você pode voltar a utilizar normalmente sua dashboard financeira!</p>
	`,
		buttonAccept: 'Ir para dashboard'
	}
}

const stepsEnum = {
	EXIT: [-1, 5],
	DOC_TYPE: 1,
	DATA_INFO: 2,
	DOCS: 3
}

const SellerRegister = () => {
	// @ts-ignore
	const { state, dispatch } = useContext(store)
	initUserSession(state, dispatch)
	const [loading, setLoading] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<IModalMessage>()

	const [checked, setChecked] = useState(false)
	const [step, setStep] = useState(0)
	const [docType, setDocType] = useState('')
	const [dataInfo, setDataInfo] = useState({ uf: '' })
	const [docs, setDocs] = useState({
		identity: null,
		residence: null,
		'work-activity': null
	})

	useEffect(() => {
		if (!dataInfo.name) {
			const obj = {}
			Object.keys(state.doctorCurrent).forEach(key => {
				if (state.doctorCurrent[key]) obj[key] = state.doctorCurrent[key]
			})
			setDataInfo({
				...obj,
				address: {
					uf: '',
					cep: '',
					complement: '',
					street: '',
					number: '',
					city: '',
					neighborhood: ''
				}
			})
		}
	}, [state.doctorCurrent])

	useEffect(() => {
		if (stepsEnum.EXIT.includes(step)) {
			// navigate(HOME)
			navigate(FINANCIAL)
		}
	}, [step])

	const handleCheckNotPerturb = e => setChecked(e.target.checked)
	const handleNext = async () => {
		try {
			if (step === stepsEnum.DOCS) {
				setLoading(true)
				const resPut = await postDoctorAddress({
					...dataInfo.address,
					uf: dataInfo.address.uf.value,
					sellerType: docType
				})
				if (resPut && resPut.status >= 300) throw new Error(`Error: ${JSON.stringify(resPut.data)}`)
				const resPost = await postSellerDocs(dataInfo.cpf, docs)
				if (resPost && resPost.status >= 300)
					throw new Error(`Error: ${JSON.stringify(resPost.data)}`)
			}
			setStep(prev => prev + 1)
		} catch (e) {
			handleShowModal({
				title: 'Ops. Não conseguimos concluir',
				message: `${e}`,
				buttonText: 'Voltar'
			})
		} finally {
			setLoading(false)
		}
	}
	const handleBack = () => setStep(prev => prev - 1)
	const isDisabled = () => {
		switch (step) {
			case stepsEnum.DOC_TYPE:
				return !docType
			case stepsEnum.DATA_INFO:
				return Object.values(dataInfo.address).some(item => !item)
			case stepsEnum.DOCS:
				return Object.values(docs).some(item => !item)
		}
	}
	const data = cosmic[`step${step}`]

	const renderStep = () => {
		switch (step) {
			case stepsEnum.DOC_TYPE:
				return <Step1 data={cosmic.step1} onChange={setDocType} type={docType} />
			case stepsEnum.DATA_INFO:
				return <Step2 data={cosmic.step2} onChange={setDataInfo} values={dataInfo} />
			case stepsEnum.DOCS:
				return <Step3 data={cosmic.step3} docs={docs} onChange={setDocs} />
			default:
				return ''
		}
	}

	const handleShowModal = (messageObj?: IModalMessage) => {
		if (messageObj && (messageObj.message || messageObj.messageHTML)) {
			setMessageToShow(messageObj)
			setShowMessage(true)
		} else setShowMessage(false)
	}

	return (
		<>
			<SEO />
			{loading && <Loader />}
			{showMessage && (
				<MessageModal
					onClose={handleShowModal}
					title={messageToShow?.title}
					message={messageToShow?.message}
					buttonText={messageToShow?.buttonText}
				/>
			)}
			{state.session.accessToken && step >= 0 && (
				<Grid padding='2vh 2vw' minHeight='95vh'>
					<Col size='12' flex justifyContent='center' /* padding='0 0 10vh 0' */>
						<Logo src={logo} alt='Logo do Saúde Digital' />
					</Col>
					<Col
						startFrom='3'
						size='8'
						flex
						flexDirection='column'
						justifyContent='center'
						alingItems='center'
					>
						{[0, 4].includes(step) && <FinancialRegister width='30vw' height='25vh' />}
						{![0, 4].includes(step) && (
							<StepNumber>
								<Span bold fontSize='12px/14px' color='white' textAlign='center' padding='5px'>
									Passo {step} de 3
								</Span>
							</StepNumber>
						)}
						<Span bold fontSize='24px/24px' wrap textAlign='center' padding='2vh 0 0' width='50%'>
							{data.title}
						</Span>
						<Span
							width='65%'
							padding='1vh 0 4vh 0'
							fontSize='14px/20px'
							wrap
							textAlign='center'
							display='block'
							dangerouslySetInnerHTML={{ __html: data.paragraph }}
						/>
						{renderStep()}
					</Col>
					<Col
						startFrom='4'
						size='6'
						flex
						justifyContent='center'
						columnGap='20px'
						margin='auto 0 0'
					>
						{data.buttonDecline && (
							<Button type='secundary' halfWidth padding='0 20px' onClick={handleBack}>
								<Span fontSize='14px/20px' bold wrap textAlign='center'>
									{data.buttonDecline}
								</Span>
							</Button>
						)}
						<Button
							type='primary'
							halfWidth
							padding='0 20px'
							onClick={handleNext}
							disabled={isDisabled()}
						>
							<Span fontSize='14px/20px' bold wrap textAlign='center'>
								{data.buttonAccept}
							</Span>
						</Button>
					</Col>
					{step === 0 && (
						<Col size='12' flex justifyContent='center'>
							<Checkbox id='checkbox-not-perturb' onChange={handleCheckNotPerturb} />

							<Span wrap fontSize='12px/14px' padding='15px 0 0 0'>
								{data.notPerturb}
							</Span>
						</Col>
					)}
				</Grid>
			)}
		</>
	)
}

export default SellerRegister
