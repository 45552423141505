import React from 'react'
import { Col, Grid, Span, InputElement } from '../styles'

const Step2 = ({ values, onChange }) => {
	const handleChange = (field: string) => e =>
		onChange({ ...values, [field]: e.target ? e.target.value : e })
	return (
		<>
			<Grid columnGap='36px'>
				<Col size='12'>
					<Span fontSize='12px/14px' bold wrap>
						Nome completo
					</Span>
					<InputElement
						withBorder
						placeholder='Digite aqui o seu nome'
						onChange={handleChange('name')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='4'>
					<Span fontSize='12px/14px' bold wrap>
						CPF
					</Span>
					<InputElement
						withBorder
						placeholder='000.000.000-00'
						onChange={handleChange('cpf')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='4'>
					<Span fontSize='12px/14px' bold wrap>
						Data de nascimento
					</Span>
					<InputElement
						withBorder
						placeholder='00/00/0000'
						onChange={handleChange('birthday')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='4'>
					<Span fontSize='12px/14px' bold wrap>
						Telefone
					</Span>
					<InputElement
						withBorder
						placeholder='(00) 0000-0000'
						onChange={handleChange('phone')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='12' padding='2vh 0 0 0'>
					<Span fontSize='12px/14px' bold wrap>
						Endereço
					</Span>
					<InputElement
						withBorder
						placeholder='Digite aqui seu endereço'
						onChange={handleChange('address')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='4'>
					<Span fontSize='12px/14px' bold wrap>
						Número
					</Span>
					<InputElement
						withBorder
						placeholder='Exemplo: 1050'
						onChange={handleChange('number')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='4'>
					<Span fontSize='12px/14px' bold wrap>
						Complemento
					</Span>
					<InputElement
						withBorder
						placeholder='Apto, bloco, casa'
						onChange={handleChange('compl')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='4'>
					<Span fontSize='12px/14px' bold wrap>
						CEP
					</Span>
					<InputElement
						withBorder
						placeholder='00000-000'
						onChange={handleChange('cep')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='4'>
					<Span fontSize='12px/14px' bold wrap>
						Bairro
					</Span>
					<InputElement
						withBorder
						placeholder='Digite seu bairro'
						onChange={handleChange('neighbor')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='4'>
					<Span fontSize='12px/14px' bold wrap>
						Cidade
					</Span>
					<InputElement
						withBorder
						placeholder='Digite sua cidade'
						onChange={handleChange('city')}
						// value={filterValues.startDate}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
				<Col size='4'>
					<Span fontSize='12px/14px' bold wrap>
						Estado
					</Span>
					<InputElement
						withBorder
						placeholder='Selecione seu estado'
						onChange={handleChange('uf')}
						value={'a'}
						// onKeyPress={handleKeyPress}
					/>
				</Col>
			</Grid>
		</>
	)
}

export default Step2
