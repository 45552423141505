import base64 from 'base-64'
// import utf8 from 'utf8'
import IDoctor from '../types/IDoctor'
import api from './api'
import { endpointsCreator } from './endpoints'

/* {
	id: 'idDoctor',
	name: 'nomeDoctor',
	cpf: 'cpfDoctor',
	email: 'emailDoctor',
	birthDay: 'birthDayDoctor',
	crm: 'crmDoctor',
	phone: 'phoneDoctor'
} */

interface IPatient {
	doctorCpf: string
	patientId: string | undefined
	patientCpf: string
	patientEmail: string
	patientName: string
	patient: IPatient
}

export const getDoctorInfo = async (doctorCpf: string) => {
	try {
		const endpoint = endpointsCreator(doctorCpf)
		const response = await api.get(endpoint.DOCTOR.GET_DOCTOR_INFO)
		if (response.data.optins) response.data.optins = response.data.optins.map(item => item.value)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const update = async (doctor: IDoctor, state: any) => {
	const url = '/doctors'
	try {
		const date = new Date(
			parseInt(doctor.birthDay.substring(6, 10)),
			parseInt(doctor.birthDay.substring(3, 5)) - 1,
			parseInt(doctor.birthDay.substring(0, 2)),
			1,
			0,
			0,
			0
		)
		// date.setFullYear(parseInt(doctor.birthDay.substring(6, 10)))
		// date.setMonth(parseInt(doctor.birthDay.substring(3, 5)) - 1)
		// date.setDate(parseInt(doctor.birthDay.substring(0, 2)))
		// date.setHours(1)
		// date.setMinutes(0)
		// date.setSeconds(0)
		const response = await api.put(url, { ...doctor, birthDay: date.toISOString() }, {})
		return response
	} catch (err) {
		return err.response
	}
}

export const getPatient = async ({ doctorCpf }: { doctorCpf: string }) => {
	const endpoints = endpointsCreator(doctorCpf)
	try {
		const response = await api.get(endpoints.DOCTOR.GET_PATIENT_DOCTOR)
		return response
	} catch (err) {
		return err.response
	}
}

interface IProps extends IDoctor {
	sellerType?: string
}
interface IAddress {
	street: string
	number: string
	complement: string
	neighborhood: string
	city: string
	uf: string
	cep: string
	sellerType: string
	bankAccount: {
		bank: string
		agency: string
		accountNumber: string
		isCurrentAccount: boolean
	}
}

export const postDoctorAddress = async (
	{ cep, uf, sellerType, bankAccount, ...address }: IAddress,
	props: any
) => {
	try {
		const bodyAssistant = {}
		if (!props?.isDoctor) bodyAssistant.doctorCpf = props.doctorCpf
		const { bank, agency, accountNumber, isCurrentAccount } = bankAccount
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.MARKETPLACE.SELLER.POST_SELLER_REGISTRATION, {
			...address,
			postalCode: cep.replace(/\D/g, ''),
			state: uf,
			account: {
				bankCode: bank,
				agency,
				account: accountNumber,
				isChecking: isCurrentAccount
			},
			...bodyAssistant
		})
		return response
	} catch (err) {
		return err.response
	}
}

export const putDoctor = async (doctor: IProps) => {
	try {
		const endpoint = endpointsCreator()
		const date = new Date(
			parseInt(doctor.birthDay.substring(6, 10)),
			parseInt(doctor.birthDay.substring(3, 5)) - 1,
			parseInt(doctor.birthDay.substring(0, 2)),
			1,
			0,
			0
		)
		// date.setFullYear(parseInt(doctor.birthDay.substring(6, 10)))
		// date.setMonth(parseInt(doctor.birthDay.substring(3, 5)) - 1)
		// date.setDate(parseInt(doctor.birthDay.substring(0, 2)))
		// date.setHours(1)
		// date.setMinutes(0)
		// date.setSeconds(0)
		const response = await api.put(endpoint.DOCTOR.PUT_UPDATE_DOCTOR, {
			...doctor,
			address: { ...doctor.address, cep: doctor.address.cep.replace(/\D/g, '') },
			birthDay: date.toISOString(),
			phone: doctor.phone.replace(/\D/g, '')
			// optins: doctor.optins.map(item => item.value)
		})
		return response
	} catch (err) {
		return err.response
	}
}

const toBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})
// TODO ainda está para trocar pelo cpf do médico
export const postSellerDocs = async (idOrCpf: string, docs: any, props: any) => {
	try {
		const bodyAssistant = {}
		if (!props?.isDoctor) bodyAssistant.doctorCpf = props.doctorCpf
		const endpoint = endpointsCreator()
		const files = await Promise.all(
			Object.keys(docs).map(async key => {
				const file = docs[key]
				const file64 = await toBase64(file).catch(e => Error(e))
				if (file64 instanceof Error) {
					console.log('Error: ', file64.message)
					throw new Error(JSON.stringify(file64))
				}
				const res = await api.post(endpoint.MARKETPLACE.SELLER.SELLER_DOCUMENTS, {
					cpf: idOrCpf,
					document: file64,
					type: key,
					extension: file.type.replace(/^([a-zA-Z]+)\/*([a-zA-Z]+)/, '$2'),
					...bodyAssistant
				})
				if (res && res.status >= 300) throw res
				return res
			})
		)

		console.log('files', files)

		return files || []
	} catch (err) {
		return err.response
	}
}
