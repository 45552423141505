import React from 'react'

export default function() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
			<defs>
				<style>{'.a-close{fill:none;}.b-close{fill:#999;}'}</style>
			</defs>
			<rect className='a-close' width='24' height='24' />
			<path
				className='b-close'
				d='M20.749,4.707,19.334,3.293l-7.292,7.293L4.749,3.293,3.334,4.707,10.627,12,3.334,19.293l1.415,1.414,7.293-7.293,7.292,7.293,1.415-1.414L13.456,12Z'
			/>
		</svg>
	)
}
