import React from 'react'

export default () => {
	let color = '#748A96'
	if (typeof window !== 'undefined') {
		if (window.localStorage.getItem('marca') === 'bradesco') color = '#0b67bd'
	}
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24.001' viewBox='0 0 24 24.001'>
			<defs>
				<style>{`.icon-search{fill:none;}.icon-search-1{fill:${color};}`}</style>
			</defs>
			<g transform='translate(0 0)'>
				<rect className='icon-search' width='24' height='24.001' transform='translate(0 0)' />
				<g transform='translate(4 4)'>
					<path
						className='icon-search-1'
						d='M18.522,17.208l-3.239-3.239a7.771,7.771,0,1,0-2.315,2.314l3.239,3.239a1.637,1.637,0,0,0,2.316-2.313ZM2.637,9.762a6.124,6.124,0,1,1,6.125,6.125A6.121,6.121,0,0,1,2.637,9.762Z'
						transform='translate(-2 -3)'
					/>
				</g>
			</g>
		</svg>
	)
}
