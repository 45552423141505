import React from 'react'
import { Grid, Span, ColDoc, FileButton } from '../styles'
import ProofID from '@cuidardigital/commons/assets/icons/proofID'
import ProofAddress from '@cuidardigital/commons/assets/icons/proofAddress'
import ProofActivity from '@cuidardigital/commons/assets/icons/proofActivity'
import Attachment from '@cuidardigital/commons/assets/icons/attachment'

const Step3 = ({ data, onChange, docs }) => {
	const handleDoc = (type: string) => e => onChange({ ...docs, [type]: e.target.files[0] })
	const hasFile = (type: string) => (docs[type] ? 'blueFullish' : '')

	const renderIcon = (type: string) => {
		switch (type) {
			case 'identity':
				return <ProofID />
			case 'residence':
				return <ProofAddress />
			default:
				return <ProofActivity />
		}
	}

	return (
		<>
			<Grid>
				{data.options.map(item => (
					<ColDoc
						key={`col-step3-${item.value}`}
						size='4'
						flex
						alingItems='center'
						justifyContent='space-between'
						flexDirection='column'
						// padding='15px 24px'
					>
						<Span className='center' padding='0 0 2vh'>
							{renderIcon(item.value)}
							<Span fontSize='20px/20px' bold padding='8px 0 0' wrap display='block'>
								{item.title}
							</Span>
							<Span fontSize='16px/20px' padding='8px 0 8px' wrap>
								{item.paragraph}
							</Span>
						</Span>
						<input
							onChange={handleDoc(item.value)}
							type='file'
							id={item.value}
							name={item.value}
							accept='image/png, image/jpeg, image/jpg, .pdf'
							hidden
						/>
						<FileButton for={item.value} active={!!hasFile(item.value)}>
							{/* <Button discreet type='blue' bg={hasFile(item.value)} for={item.value}> */}
							<Attachment active={!!hasFile(item.value)} />
							<Span
								fontSize='14px/16px'
								bold
								padding='0 8px'
								wrap
								color={!hasFile(item.value) && 'white'}
							>
								{item.button}
							</Span>
							{/* </Button> */}
						</FileButton>
					</ColDoc>
				))}
			</Grid>
		</>
	)
}

export default Step3
